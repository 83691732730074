const timezones = [
  {
    value: "Europe/London",
    label: "Лондон (UTC +0:00)",
  },
  {
    value: "Europe/Berlin",
    label: "Берлин (UTC +1:00)",
  },
  {
    value: "Europe/Paris",
    label: "Париж (UTC +1:00)",
  },
  {
    value: "Europe/Rome",
    label: "Рим (UTC +1:00)",
  },
  {
    value: "Europe/Kaliningrad",
    label: "Калининград (UTC +2:00)",
  },
  {
    value: "Europe/Athens",
    label: "Афины (UTC +2:00)",
  },
  {
    value: "Africa/Cairo",
    label: "Каир (UTC +2:00)",
  },
  {
    value: "Africa/Nairobi",
    label: "Найроби (UTC +3:00)",
  },
  {
    value: "Europe/Moscow",
    label: "Москва (UTC +3:00)",
  },
  {
    value: "Asia/Dubai",
    label: "Дубай (UTC +4:00)",
  },
  {
    value: "Europe/Samara",
    label: "Самара (UTC +4:00)",
  },
  {
    value: "Asia/Kolkata",
    label: "Калькутта (UTC +5:30)",
  },
  {
    value: "Asia/Yekaterinburg",
    label: "Екатеринбург (UTC +5:00)",
  },
  {
    value: "Asia/Omsk",
    label: "Омск (UTC +6:00)",
  },
  {
    value: "Asia/Novosibirsk",
    label: "Новосибирск (UTC +7:00)",
  },
  {
    value: "Asia/Krasnoyarsk",
    label: "Красноярск (UTC +7:00)",
  },
  {
    value: "Asia/Shanghai",
    label: "Шанхай (UTC +8:00)",
  },
  {
    value: "Asia/Irkutsk",
    label: "Иркутск (UTC +8:00)",
  },
  {
    value: "Asia/Yakutsk",
    label: "Якутск (UTC +9:00)",
  },
  {
    value: "Asia/Seoul",
    label: "Сеул (UTC +9:00)",
  },
  {
    value: "Asia/Tokyo",
    label: "Токио (UTC +9:00)",
  },
  {
    value: "Asia/Vladivostok",
    label: "Владивосток (UTC +10:00)",
  },
  {
    value: "Australia/Sydney",
    label: "Сидней (UTC +10:00)",
  },
  {
    value: "Australia/Melbourne",
    label: "Мельбурн (UTC +10:00)",
  },
  {
    value: "Asia/Magadan",
    label: "Магадан (UTC +11:00)",
  },
  {
    value: "Asia/Kamchatka",
    label: "Камчатка (UTC +12:00)",
  },
  {
    value: "America/New_York",
    label: "Нью-Йорк (UTC -5:00)",
  },
  {
    value: "America/Toronto",
    label: "Торонто (UTC -5:00)",
  },
  {
    value: "America/Chicago",
    label: "Чикаго (UTC -6:00)",
  },
  {
    value: "America/Denver",
    label: "Денвер (UTC -7:00)",
  },
  {
    value: "America/Los_Angeles",
    label: "Лос-Анджелес (UTC -8:00)",
  },
  {
    value: "America/Vancouver",
    label: "Ванкувер (UTC -8:00)",
  },
];

export default timezones;
