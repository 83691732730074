import {
  useNavigate,
  Form as RouterForm,
  useActionData,
  useNavigation,
} from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { RiSaveLine, RiArrowGoBackFill } from 'react-icons/ri';

import AlertMessage from './AlertMessage';
import Transitions from '../animations/Transition';

const NoModalForm = (props) => {
  const navigate = useNavigate();
  const data = useActionData();
  const { state } = useNavigation();
  return (
    <>
      <Transitions>
        <RouterForm method='post' /* onSubmit={customSubmit} */>
          <h1>{props.title}</h1>
          <hr></hr>
          {data && data.errors && (
            <AlertMessage
              variant='danger'
              message={Object.values(data.errors).map((err) => (
                <li key={err}>{err}</li>
              ))}
            />
          )}
          {data && data.message && data.error && (
            <AlertMessage variant='danger' message={data.message} />
          )}
          {data && data.message && !data.error && (
            <AlertMessage variant='success' message={data.message} />
          )}
          {props.children}
          <hr></hr>
          <Row>
            <Col sm='auto'>
              <Button
                onClick={() => navigate(-1, { state: {} })}
                className='mb-2'
              >
                <RiArrowGoBackFill /> Назад
              </Button>
            </Col>
            <Col sm='auto'>
              <Button
                variant='primary'
                type='submit'
                disabled={state !== 'idle'}
              >
                <RiSaveLine /> Сохранить
              </Button>
            </Col>
          </Row>
        </RouterForm>
      </Transitions>
    </>
  );
};

export default NoModalForm;
