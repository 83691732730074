import { Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Accordion from "react-bootstrap/Accordion";

import Transitions from "../animations/Transition";
import { getLocalStorageData } from "../util/auth";
import { getInitialPrefsData } from "../util/prefs";
import { MobileView } from "react-device-detect";

const Footer = () => {
  const { token } = getLocalStorageData();
  const { contacts } = getInitialPrefsData();
  const isLoggedIn = !!token;

  return (
    <>
      {isLoggedIn && (
        <Container fluid className="mt-3 px-5" style={{ maxWidth: "1920px" }}>
          <Transitions>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Наши контакты</Accordion.Header>
                <Accordion.Body>
                  <p>
                    Телефон: <a href={`tel:${contacts.tel}`}>{contacts.tel}</a>
                  </p>
                  <p>
                    Email: <a href="mailto:">{contacts.email}</a>
                  </p>
                  <p>Адрес: {contacts.address}</p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Transitions>
        </Container>
      )}

      <footer className="page-footer pt-4 mt-auto">
        <div className="text-center">
          <Link to="/changelog">Changelog</Link>
        </div>
        <div className="footer-copyright text-center py-3">
          © {new Date().getFullYear()} F1Lab Helpdesk
        </div>
        <div className="text-center pb-3">
          Версия {process.env.REACT_APP_VERSION}
        </div>
        <MobileView>
          <div style={{ marginBottom: "80px" }}></div>
        </MobileView>
      </footer>
    </>
  );
};

export default Footer;
