import { useState } from 'react';

import Badge from 'react-bootstrap/Badge';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';

const ApplicantModal = (props) => {
    const [showApplicantModal, setShowApplicantModal] = useState(false);

    const applicantModalShowHandler = () => {
        setShowApplicantModal(true);
    };

    const applicantModalCloseHandler = () => {
        setShowApplicantModal(false);
    };

    return (
        <>
            <Badge bg="primary" onClick={applicantModalShowHandler}>
                {`${props.ticket.applicant?.lastName} ${props.ticket.applicant?.firstName}`}
            </Badge>
            <Modal
                centered
                show={showApplicantModal}
                onHide={applicantModalCloseHandler}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Данные инициатора Заявки {props.ticket.num}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table striped bordered hover>
                        <tbody>
                            <tr>
                                <th>Имя</th>
                                <td>
                                    {props.ticket.applicant.lastName}{' '}
                                    {props.ticket.applicant.firstName}
                                </td>
                            </tr>
                            <tr>
                                <th>Должность</th>
                                <td>{props.ticket.applicant.position}</td>
                            </tr>
                            <tr>
                                <th>Телефон</th>
                                <td>
                                    <a
                                        href={`tel:${props.ticket.applicant.phone}`}
                                    >
                                        {props.ticket.applicant.phone}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <th>Email</th>
                                <td>
                                    <a
                                        href={`mailto:${props.ticket.applicant.email}`}
                                    >
                                        {props.ticket.applicant.email}
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={applicantModalCloseHandler}
                    >
                        Закрыть
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ApplicantModal;
