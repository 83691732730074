import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";

import { RiAddBoxLine } from "react-icons/ri";

const AddButton = ({ to, content }) => {
  return (
    <Button as={Link} to={to} className="w-100" size="lg">
      <RiAddBoxLine /> {content}
    </Button>
  );
};

export default AddButton;
