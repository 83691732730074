import { useRef, useState, useContext } from "react";
import { useFetcher } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";

import { RiTimeLine } from "react-icons/ri";

import { changeTimezone } from "../../../util/format-date";

import { AuthedUserContext } from "../../../store/authed-user-context";

const UpdateDeadline = ({ ticket, isOverdue }) => {
  const fetcher = useFetcher();
  const { _id: userId } = useContext(AuthedUserContext);

  const deadlineInputRef = useRef();

  const [show, setShow] = useState(false);

  const showModal = () => {
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
  };

  const updateDeadlineHandler = (event) => {
    event.preventDefault();

    fetcher.submit(
      {
        intent: "updateDeadline",
        _id: ticket._id,
        deadline: new Date(deadlineInputRef.current.value),
      },
      {
        method: "POST",
        action: `/tickets/${ticket.num}`,
      },
    );

    closeModal();
  };

  return (
    <>
      {ticket.responsibles
        .map((user) => user._id.toString())
        .includes(userId) &&
        ticket.state !== "Выполнена" &&
        ticket.state !== "Закрыта" && (
          <>
            <Dropdown.Item
              variant={isOverdue ? "danger" : "info"}
              onClick={showModal}
            >
              <RiTimeLine /> Изменить дедлайн
            </Dropdown.Item>
            <Dropdown.Divider />
            <Modal show={show} onHide={closeModal} centered>
              <Modal.Header closeButton>
                <Modal.Title>Изменение дедлайна</Modal.Title>
              </Modal.Header>
              <Form onSubmit={updateDeadlineHandler}>
                <Modal.Body>
                  <Form.Group className="mb-3">
                    <Form.Label>Дедлайн</Form.Label>
                    <Form.Control
                      type="datetime-local"
                      ref={deadlineInputRef}
                      defaultValue={changeTimezone(new Date(ticket.deadline))}
                    />
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={closeModal}
                    disabled={fetcher.state !== "idle"}
                  >
                    Закрыть
                  </Button>
                  <Button
                    type="submit"
                    name="intent"
                    value="updateDeadline"
                    disabled={fetcher.state !== "idle"}
                  >
                    Подтвердить
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          </>
        )}
    </>
  );
};

export default UpdateDeadline;
