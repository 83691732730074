import { Outlet, useNavigation } from "react-router-dom";
import { redirect, json, useLoaderData, Link } from "react-router-dom";

import { RiRefreshLine } from "react-icons/ri";
import { RxUpdate } from "react-icons/rx";

import AddButton from "../../UI/AddButton";

import Transitions from "../../animations/Transition";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";

import { getLocalStorageData } from "../../util/auth";
import Spinner from "../../animations/Spinner";

const Changelog = () => {
  const { state } = useNavigation();
  const { canUpdateChangelog } = getLocalStorageData();
  const data = useLoaderData();
  const logEntries = data.logEntries ? data.logEntries : [];

  return (
    <Container fluid>
      <h1 className="display-4">
        <RxUpdate /> Changelog
      </h1>
      <hr></hr>
      {canUpdateChangelog && (
        <>
          <Row>
            <Col sm="auto">
              <Button as={Link} to="." size="lg" className="w-100">
                <RiRefreshLine /> Обновить
              </Button>
            </Col>
            <Col sm="auto">
              <AddButton content="Добавить" to="/changelog/add" />
            </Col>
          </Row>
          <hr className="mt-0"></hr>
        </>
      )}
      {logEntries.length > 0 && (
        <>
          {state === "idle" && (
            <Transitions>
              {logEntries.map((entry) => {
                return (
                  <div key={entry._id}>
                    <Row>
                      <Col>
                        <h4 className="mt-3 mb-0">
                          <small className="text-muted">
                            {new Date(entry.createdAt).toLocaleDateString(
                              "ru-RU",
                              {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              },
                            )}
                          </small>
                        </h4>
                        <h1 className="display-6">{entry.title}</h1>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: entry.body,
                          }}
                        ></p>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </Transitions>
          )}
          {state === "loading" && (
            <Transitions>
              <Spinner />
            </Transitions>
          )}
        </>
      )}

      <Outlet />
    </Container>
  );
};

export default Changelog;

export async function loader() {
  document.title = "CHANGELOG";

  const { token } = getLocalStorageData();

  const response = await fetch(
    `${process.env.REACT_APP_ADDRESS}/api/changelog`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  );

  if (!response.ok) {
    throw response;
  }

  return response;
}
