import TicketCategoryItem from './Item';

const TicketCategoriesList = (props) => {
    return (
        <>
            {props.items.map((category) => (
                <TicketCategoryItem
                    key={category._id.toString()}
                    category={category}
                    setList={props.setList}
                />
            ))}
        </>
    );
};

export default TicketCategoriesList;
