import TicketItem from "../Ticket/Item";
import AlertMessage from "../../UI/AlertMessage";

const TicketsList = ({ items = [] }) => {
  return (
    <>
      {items.length > 0 && (
        <>
          {items.map((ticket) => (
            <TicketItem key={ticket._id.toString()} ticket={ticket} />
          ))}
        </>
      )}
      {items.length === 0 && (
        <AlertMessage
          variant="light"
          message="Заявки по текущему запросу не найдены"
        />
      )}
    </>
  );
};

export default TicketsList;
