import { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import { Outlet, useLoaderData, useSubmit } from "react-router-dom";

import { AuthedUserContext } from "../store/authed-user-context";
import { SidebarContext } from "../store/sidebar-context";

import NavigationBar from "./Navbar";
import Footer from "./Footer";
import AlertToast from "../UI/AlertToast";

import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import ToastContainer from "react-bootstrap/ToastContainer";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Transitions from "../animations/Transition";

import ChangelogAlert from "./ChangelogAlert";

import useHttp from "../hooks/use-http";
import MobileBottomNavbar from "./MobileBottomNavbar";

import { getLocalStorageData, getTokenDuration } from "../util/auth";

const RootLayout = () => {
  const { token, userId } = getLocalStorageData();
  const { appVersion, userData } = useLoaderData();

  const [showLeftSidebar, setShowLeftSidebar] = useState(false);
  const [leftSidebarContent, setLeftSidebarContent] = useState(<></>);

  const location = useLocation();

  useEffect(() => {
    if (["/tickets", "/users"].includes(location.pathname)) {
      return setShowLeftSidebar(true);
    }
    setLeftSidebarContent(<></>);
    setShowLeftSidebar(false);
  }, [location]);

  const submit = useSubmit();
  const isLoggedIn = !!token;

  useEffect(() => {
    if (!token) {
      return;
    }

    if (token === "EXPIRED") {
      submit(null, { action: "/logout", method: "POST" });
      return;
    }

    const tokenDuration = getTokenDuration();

    setTimeout(() => {
      submit(null, { action: "/logout", method: "POST" });
    }, tokenDuration);
  }, [token, submit]);

  const [userPermissions, setUserPermissions] = useState([]);

  const { sendRequest: fetchUserHandler } = useHttp();
  const fetchUser = useCallback(() => {
    fetchUserHandler(
      {
        url: `${process.env.REACT_APP_ADDRESS}/api/users/${userId}`,
        headers: {
          Authorization: "Bearer " + token,
        },
      },
      (data) => {
        if (data.permissions) {
          setUserPermissions(data.permissions);
        }
      },
    );
  }, [fetchUserHandler, token, userId]);

  useEffect(() => {
    if (!token) {
      return;
    }
    fetchUser();
  }, [fetchUser, token]);

  return (
    <AuthedUserContext.Provider value={userData}>
      {isLoggedIn && <NavigationBar userPermissions={userPermissions} />}
      <Transitions>
        <BrowserView>
          <Container
            fluid
            style={{ maxWidth: "1920px", marginTop: "100px" }}
            className="px-5"
          >
            {appVersion !== process.env.REACT_APP_VERSION && (
              <Alert variant="warning" className="mb-4" dismissible>
                Версия приложения в браузере не совпадает с версией на сервере.
                Пожалуйста, обновите кэш страницы нажатием клавиш CTRL+SHIFT+R
              </Alert>
            )}
            <ChangelogAlert />
            <SidebarContext.Provider
              value={{
                showLeftSidebar: showLeftSidebar,
                setShowLeftSidebar: setShowLeftSidebar,
                leftSidebarContent: leftSidebarContent,
                setLeftSidebarContent: setLeftSidebarContent,
              }}
            >
              <Row>
                {showLeftSidebar && (
                  <Col
                    hidden={!showLeftSidebar}
                    className="d-none d-md-block col-4 col-xl-3"
                  >
                    <Transitions>
                      <Card
                        className="mb-3 shadow"
                        style={{ minHeight: "calc(100svh - 124px)" }}
                      >
                        <Card.Body>{leftSidebarContent}</Card.Body>
                      </Card>
                    </Transitions>
                  </Col>
                )}
                <Col>
                  <Card
                    className="mb-3 shadow"
                    style={{ minHeight: "calc(100svh - 124px)" }}
                  >
                    <Card.Body>
                      <Outlet />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </SidebarContext.Provider>
          </Container>
        </BrowserView>
        <MobileView>
          <Container style={{ marginTop: "100px" }}>
            <Outlet />
          </Container>
          <MobileBottomNavbar />
        </MobileView>
      </Transitions>
      <ToastContainer className="p-3" position="bottom-end">
        <AlertToast />
      </ToastContainer>
      <Footer />
    </AuthedUserContext.Provider>
  );
};

export default RootLayout;
