const { getLocalStorageData } = require("./auth");

exports.formatDate = (date) => {
  const { timezone } = getLocalStorageData();
  return new Date(date).toLocaleDateString("ru", {
    timeZone: timezone || "Asia/Vladivostok",
    weekday: "short",
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

exports.formatShortDate = (date) => {
  const { timezone } = getLocalStorageData();
  return new Date(date).toLocaleDateString("ru", {
    timeZone: timezone || "Asia/Vladivostok",
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
};

exports.formatDateTime = (date) => {
  const { timezone } = getLocalStorageData();
  return new Date(date).toLocaleDateString("ru", {
    timeZone: timezone || "Asia/Vladivostok",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

exports.changeTimezone = (date) => {
  const invdate = new Date(
    date.toLocaleString("en-US", {
      timeZone: "UTC",
    }),
  );

  const diff = date.getTime() - invdate.getTime();

  return new Date(date.getTime() + diff).toISOString().slice(0, 16);
};

exports.timeDateInputFormat = (date) => {
  const invdate = new Date(
    date.toLocaleString("en-US", {
      timeZone: "UTC",
    }),
  );

  const diff = date.getTime() - invdate.getTime();

  return new Date(date.getTime() + diff).toISOString().slice(0, 16);
};
