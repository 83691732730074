import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./auth";
import getScreenSlice from "./getScreen";
import prefsSlice from "./prefs";
import toastSlice from "./toast";
import ticketsFilter from "./filter-tickets";
import usersFilter from "./filter-users";

const store = configureStore({
  reducer: {
    auth: authReducer,
    toast: toastSlice.reducer,
    prefs: prefsSlice.reducer,
    getScreen: getScreenSlice.reducer,
    ticketsFilter: ticketsFilter.reducer,
    usersFilter: usersFilter.reducer,
  },
});

export default store;
