import {
    useNavigate,
    Form as RouterForm,
    useActionData,
} from 'react-router-dom';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { RiSaveLine } from 'react-icons/ri';

import AlertMessage from '../UI/AlertMessage';

const ModalForm = (props) => {
    const navigate = useNavigate();
    const data = useActionData();
    return (
        <>
            <Modal
                show={true}
                onHide={() => {
                    navigate(props.navigateTo);
                }}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>

                <RouterForm method='post'>
                    <Modal.Body>
                        {data && data.errors && (
                            <AlertMessage
                                variant='danger'
                                message={Object.values(data.errors).map(
                                    (err) => (
                                        <li key={err}>{err}</li>
                                    )
                                )}
                            />
                        )}
                        {data && data.message && (
                            <AlertMessage
                                variant='danger'
                                message={data.message}
                            />
                        )}
                        {props.children}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            onClick={() => {
                                navigate(props.navigateTo);
                            }}
                        >
                            Закрыть
                        </Button>
                        <Button variant='primary' type='submit'>
                            <RiSaveLine /> Сохранить
                        </Button>
                    </Modal.Footer>
                </RouterForm>
            </Modal>
        </>
    );
};

export default ModalForm;
