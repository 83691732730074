import {
  Form as RouterForm,
  useSearchParams,
  useNavigation,
  Link,
} from 'react-router-dom';
import { useState } from 'react';

import AlertMessage from '../../UI/AlertMessage';

import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const ForgotPassword = ({ data }) => {
  const [searchParams] = useSearchParams();
  const navigation = useNavigation();

  const forgotPassword = searchParams.get('mode') === 'forgot-password';
  const isSubmitting = navigation.state === 'submitting';

  const [email, setEmail] = useState('');
  const emailChangeHandler = (event) => {
    setEmail(event.target.value);
  };

  return (
    <>
      {forgotPassword && (
        <>
          {data && data.errors && (
            <AlertMessage
              variant='danger'
              message={Object.values(data.errors).map((err) => (
                <li key={err}>{err}</li>
              ))}
            />
          )}
          {data && data.message && (
            <>
              <Row className='justify-content-center'>
                <Col sm='auto'>
                  <AlertMessage variant='danger' message={data.message} />
                </Col>
              </Row>
              <Row className='justify-content-center'>
                <Col sm='auto'>
                  <Button
                    size='lg'
                    as={Link}
                    to='?mode=forgot-password'
                    className='me-4'
                  >
                    Ввести другой E-Mail
                  </Button>
                </Col>
              </Row>
            </>
          )}
          {!data && (
            <RouterForm method='post'>
              <Row className='justify-content-center mb-4'>
                <Col sm='8'>
                  <Form.Group className='mb-3'>
                    <FloatingLabel label='Введите E-Mail' className='mb-3'>
                      <Form.Control
                        required
                        type='email'
                        name='email'
                        placeholder='Введите E-Mail'
                        value={email}
                        onChange={emailChangeHandler}
                      />
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group className='text-end'>
                    <Link to='?mode=login' className='me-4'>
                      Войти
                    </Link>
                    <Button
                      size='lg'
                      variant='primary'
                      type='submit'
                      disabled={isSubmitting}
                      intent='password-recovery'
                    >
                      {isSubmitting ? 'Подождите...' : 'Сбросить пароль'}
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
            </RouterForm>
          )}
          {data && data.emailSent && (
            <>
              <Row className='justify-content-center'>
                <Col sm='auto'>
                  <AlertMessage
                    variant='success'
                    message='Отправили письмо с ссылкой на восстановление доступа к порталу. Проверьте Ваш почтовый ящик'
                  />
                </Col>
                <Col sm='auto'>
                  <Button size='lg' as={Link} to='?mode=login' className='me-4'>
                    Войти
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ForgotPassword;
