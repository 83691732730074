import { useState, useRef, useContext } from "react";
import { useDispatch } from "react-redux";

import useHttp from "../../hooks/use-http";

import { timeDateInputFormat } from "../../util/format-date";
import { msToHMS } from "../../util/time-helpers";

import Select from "../../UI/Select";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";

import { toastActions } from "../../store/toast";

import { AuthedUserContext } from "../../store/authed-user-context";
import { getLocalStorageData } from "../../util/auth";

const AddWork = ({
  ticket,
  setWorks,
  setCloseTicketIsActive,
  responsibles,
  otherCompanyTickets,
}) => {
  const dispatch = useDispatch();

  const { token } = getLocalStorageData();
  const { isAdmin, _id: userId } = useContext(AuthedUserContext);

  const usersList = responsibles;
  const [description, setDescription] = useState("");
  const [finishedBy, setFinishedBy] = useState();
  const [visitRequired, setVisitRequired] = useState(false);

  const startedAtInputRef = useRef();
  const finishedAtInputRef = useRef();

  const [linkToTickets, setLinkToTickets] = useState([]);

  const descriptionChangeHandler = (event) => {
    setDescription(event.target.value);
  };

  const finishedByChangeHandler = (selectedItem) => {
    setFinishedBy(selectedItem);
  };

  const setMe = () => {
    setFinishedBy(
      usersList.filter((user) => user._id.toString() === userId.toString())[0],
    );
  };

  const visitRequiredHandler = () => {
    setVisitRequired(!visitRequired);
  };

  const linkToTicketsHandler = (selectedItems) => {
    setLinkToTickets(selectedItems);
  };

  const { sendRequest: postWorkHandler } = useHttp();

  const [minDate, setMinDate] = useState("");
  const minDateChangeHandler = (event) => {
    setMinDate(event.target.value);
    workDurationHandler();
  };

  const submitHandler = (event) => {
    event.preventDefault();

    const linkedTickets = linkToTickets.map((ticket) => ticket._id);
    linkedTickets.unshift(ticket._id);

    const work = {
      tickets: linkedTickets,
      company: ticket.company?._id,
      ticketCategory: ticket.category,
      ticketApplicant: ticket.applicant,
      description: description,
      visitRequired: visitRequired,
      startedAt: new Date(startedAtInputRef.current.value),
      finishedAt: new Date(finishedAtInputRef.current.value),
      finishedBy: isAdmin ? finishedBy : userId,
    };

    const createWork = (data) => {
      dispatch(
        toastActions.setState({
          variant: "success text-white",
          message: "Работа добавлена",
          show: true,
        }),
      );

      setShowAddModal(false);

      const createdWork = {
        _id: data.work._id,
        tickets: data.work.tickets,
        description: data.work.description,
        visitRequired: data.work.visitRequired,
        startedAt: data.work.startedAt,
        finishedAt: data.work.finishedAt,
        finishedBy: data.work.finishedBy,
        createdBy: data.work.createdBy,
        createdAt: data.work.createdAt,
      };
      setWorks((prevWorksList) => [...prevWorksList, createdWork]);

      setCloseTicketIsActive(true);
      ticket.works.push(createdWork._id);
    };

    postWorkHandler(
      {
        url: `${process.env.REACT_APP_ADDRESS}/api/works/add/`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: work,
      },
      (data) => {
        if (data.work) {
          setDescription("");
          setFinishedBy("");
          startedAtInputRef.current.value = "";
          finishedAtInputRef.current.value = "";
          createWork(data);
        } else {
          dispatch(
            toastActions.setState({
              variant: "danger text-white",
              message: data.message,
              show: true,
            }),
          );
        }
      },
    );
  };

  const [showAddModal, setShowAddModal] = useState(false);

  const handleCloseAddModal = () => setShowAddModal(false);
  const handleShowAddModal = () => setShowAddModal(true);

  const addMinutes = (minutes) => {
    const date = finishedAtInputRef.current.value
      ? new Date(finishedAtInputRef.current.value)
      : startedAtInputRef.current.value
        ? new Date(startedAtInputRef.current.value)
        : new Date();
    finishedAtInputRef.current.value = timeDateInputFormat(
      new Date(date.getTime() + minutes * 60000),
    );
    workDurationHandler();
    return;
  };

  const substractMinutes = (minutes) => {
    const date = startedAtInputRef.current.value
      ? new Date(startedAtInputRef.current.value)
      : finishedAtInputRef.current.value
        ? new Date(finishedAtInputRef.current.value)
        : new Date();
    startedAtInputRef.current.value = timeDateInputFormat(
      new Date(date.getTime() - minutes * 60000),
    );
    workDurationHandler();
    return;
  };

  const startedNowHandler = () => {
    startedAtInputRef.current.value = timeDateInputFormat(new Date());
    workDurationHandler();
  };

  const finishedNowHandler = () => {
    finishedAtInputRef.current.value = timeDateInputFormat(new Date());
    workDurationHandler();
  };

  const [workDuration, setWorkDuration] = useState("00:00 ч.");

  const workDurationHandler = () => {
    if (finishedAtInputRef.current?.value && startedAtInputRef.current?.value) {
      setWorkDuration(
        msToHMS(
          new Date(finishedAtInputRef.current?.value || "") -
            new Date(startedAtInputRef.current?.value || ""),
        ),
      );
    } else {
      setWorkDuration("00:00 ч.");
    }
  };

  return (
    <>
      <Col md="auto">
        <Button
          variant="outline-info"
          size="lg"
          className="mb-2 w-100"
          onClick={handleShowAddModal}
        >
          Добавить
        </Button>
      </Col>

      <Modal
        centered
        size="lg"
        show={showAddModal}
        onHide={handleCloseAddModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Добавить работы</Modal.Title>
        </Modal.Header>
        <Form onSubmit={submitHandler}>
          <Modal.Body>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column md="3">
                Выезд
              </Form.Label>
              <Col md="auto" className="mt-2">
                <Form.Check
                  value={visitRequired}
                  onClick={visitRequiredHandler}
                  type="switch"
                  name="visitRequired"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column md="3">
                Описание
              </Form.Label>
              <Col md="9" className="mb-3">
                <Form.Control
                  required
                  value={description}
                  onChange={descriptionChangeHandler}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column md="3">
                Начало
              </Form.Label>
              <Col sm="4" className="mb-3">
                <Form.Control
                  required
                  type="datetime-local"
                  ref={startedAtInputRef}
                  onChange={minDateChangeHandler}
                />
              </Col>
              <Col xs="auto" className="mb-3">
                <Button onClick={startedNowHandler}>Сейчас</Button>
              </Col>
              <Col xs="auto" className="mb-3">
                <Button
                  onClick={() => {
                    substractMinutes(10);
                  }}
                >
                  -10 мин
                </Button>
              </Col>
              <Col xs="auto">
                <Button
                  onClick={() => {
                    substractMinutes(30);
                  }}
                >
                  -30 мин
                </Button>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column md="3">
                Окончание
              </Form.Label>
              <Col md="4" className="mb-3" onChange={workDurationHandler}>
                <Form.Control
                  required
                  type="datetime-local"
                  ref={finishedAtInputRef}
                  min={minDate}
                />
              </Col>
              <Col xs="auto" className="mb-3">
                <Button onClick={finishedNowHandler}>Сейчас</Button>
              </Col>
              <Col xs="auto" className="mb-3">
                <Button
                  onClick={() => {
                    addMinutes(10);
                  }}
                >
                  +10 мин
                </Button>
              </Col>
              <Col xs="auto">
                <Button
                  onClick={() => {
                    addMinutes(30);
                  }}
                >
                  +30 мин
                </Button>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="3">
                Длительность
              </Form.Label>
              <Form.Label column sm="9">
                <strong>{workDuration}</strong>
              </Form.Label>
            </Form.Group>
            {isAdmin && (
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Исполнитель
                </Form.Label>
                <Col sm="8" className="mb-3">
                  <Select
                    isSearchable
                    required
                    options={usersList}
                    value={finishedBy}
                    getOptionLabel={(option) =>
                      `${option.lastName} ${option.firstName}`
                    }
                    getOptionValue={(option) => option._id}
                    onChange={finishedByChangeHandler}
                  />
                </Col>
                <Col xs="auto">
                  <Button onClick={setMe}>Я</Button>
                </Col>
              </Form.Group>
            )}
            <Form.Group as={Row} className="mb-3">
              <Form.Label htmlFor="otherCompanyTickets" column sm="3">
                Также привязать к
              </Form.Label>
              <Col sm="9" className="mb-3">
                <Select
                  id="otherCompanyTickets"
                  placeholder="Выберите заявки"
                  closeMenuOnSelect={false}
                  isClearable
                  isSearchable
                  isMulti
                  value={linkToTickets}
                  options={otherCompanyTickets}
                  getOptionLabel={(option) => `${option.num} / ${option.title}`}
                  getOptionValue={(option) => option._id}
                  onChange={linkToTicketsHandler}
                />
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseAddModal}>
              Закрыть
            </Button>
            <Button type="submit" variant="primary">
              Добавить
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default AddWork;
