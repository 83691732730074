import { NavLink } from 'react-router-dom';

import Badge from 'react-bootstrap/Badge';

import ItemCard from '../../UI/ItemCard';

import { RiAccountBoxLine } from 'react-icons/ri';

function CompanyItem(props) {
    const { _id, alias, fullTitle, users, createdAt } = props.company;
    return (
        <ItemCard createdAt={createdAt}>
            <h4>
                <NavLink to={`${_id}`}>{alias}</NavLink>{' '}
                <Badge bg='secondary' className='mx-2'>
                    <RiAccountBoxLine /> {users.length}
                </Badge>
            </h4>
            <p className='mb-0'>{fullTitle}</p>
        </ItemCard>
    );
}

export default CompanyItem;
