import { useState } from 'react';

import Form from 'react-bootstrap/Form';

const PrefsIntegrations = (props) => {
    const [getScreenIsActive, setGetScreenIsActive] = useState(
        props.prefs.getScreen?.isActive
    );

    const getScreenIsActiveChangeHandler = () => {
        setGetScreenIsActive(!getScreenIsActive);
        props.prefs.getScreen.isActive = !getScreenIsActive;
    };

    const [sendPulseIsActive, setSendPulseIsActive] = useState(
        props.prefs.sendPulse?.isActive
    );

    const sendPulseIsActiveChangeHandler = () => {
        setSendPulseIsActive(!sendPulseIsActive);
        props.prefs.sendPulse.isActive = !sendPulseIsActive;
    };

    const [sendpulseApiUserId, setSendpulseApiUserId] = useState(
        props.prefs.sendPulse?.apiUserId
    );
    const [sendpulseApiSecret, setSendpulseApiSecret] = useState(
        props.prefs.sendPulse?.apiSecret
    );

    const sendpulseApiUserIdHandler = (event) => {
        setSendpulseApiUserId(event.target.value);
        props.prefs.sendPulse.apiUserId = event.target.value;
    };

    const sendpulseApiSecretHandler = (event) => {
        setSendpulseApiSecret(event.target.value);
        props.prefs.sendPulse.apiSecret = event.target.value;
    };

    return (
        <>
            <Form.Group className='mb-3'>
                <Form.Label>
                    <h3>Getscreen</h3>
                </Form.Label>
                <Form.Check
                    type='switch'
                    label='Использовать GetScreen для удалённого подключения к Клиентам'
                    checked={getScreenIsActive}
                    value={getScreenIsActive}
                    onChange={getScreenIsActiveChangeHandler}
                />
            </Form.Group>
            <hr></hr>
            <Form.Group className='mb-3'>
                <Form.Label>
                    <h3>SendPulse</h3>
                </Form.Label>
                <Form.Check
                    type='switch'
                    label='Использовать SendPulse для отправки уведомлений'
                    checked={sendPulseIsActive}
                    value={sendPulseIsActive}
                    onChange={sendPulseIsActiveChangeHandler}
                />
            </Form.Group>
            <Form.Group className='mb-3'>
                <Form.Label>ID пользователя Sendpulse</Form.Label>
                <Form.Control
                    disabled={!sendPulseIsActive}
                    required
                    type='password'
                    value={sendpulseApiUserId}
                    onChange={sendpulseApiUserIdHandler}
                />
            </Form.Group>
            <Form.Group className='mb-3'>
                <Form.Label>API-секрет Sendpulse</Form.Label>
                <Form.Control
                    disabled={!sendPulseIsActive}
                    required
                    type='password'
                    value={sendpulseApiSecret}
                    onChange={sendpulseApiSecretHandler}
                />
            </Form.Group>
        </>
    );
};

export default PrefsIntegrations;
