import { redirect } from "react-router-dom";

import AddChangelog from "../../components/Changelog/AddModal";
import { getLocalStorageData } from "../../util/auth";

const AddChangelogPage = () => {
  return <AddChangelog />;
};

export default AddChangelogPage;

export async function action({ request }) {
  const { token } = getLocalStorageData();

  const data = await request.formData();

  const changelogData = {
    title: data.get("title"),
    body: data.get("body"),
    isPublic: data.get("isPublic") === "true",
  };

  const response = await fetch(
    `${process.env.REACT_APP_ADDRESS}/api/changelog/add`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(changelogData),
    },
  );

  if (!response.ok) {
    throw response;
  }

  return redirect("/changelog");
}
