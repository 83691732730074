import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { getLocalStorageData } from "../../../util/auth";

const Appearance = () => {
  const [darkMode, setDarkMode] = useState(getLocalStorageData()?.darkMode);

  const darkModeHandler = () => {
    setDarkMode(!darkMode);
    window.location.reload();
  };

  useEffect(() => {
    if (darkMode) {
      localStorage.setItem("darkMode", true);
    } else {
      localStorage.setItem("darkMode", false);
    }
  }, [darkMode]);
  return (
    <>
      <Form.Check
        type="switch"
        label="Тёмная тема"
        checked={darkMode}
        onChange={darkModeHandler}
      />
    </>
  );
};

export default Appearance;
