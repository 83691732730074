import { useState } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { RiDeleteBinLine } from 'react-icons/ri';
import { Form as RouterForm } from 'react-router-dom';

import Form from 'react-bootstrap/Form';

const DeleteRoutineTask = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button variant='danger' onClick={handleShow} className='mx-2'>
                <RiDeleteBinLine /> Удалить
            </Button>

            <Modal show={show} onHide={handleClose} centered>
                <RouterForm method='post'>
                    <Modal.Header closeButton>
                        <Modal.Title>{props.routineTask.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Вы уверены? Это действие нельзя отменить.
                        <Form.Control
                            name='id'
                            type='text'
                            hidden
                            readOnly
                            value={props.routineTask._id}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='secondary' onClick={handleClose}>
                            Закрыть
                        </Button>
                        <Button variant='danger' type='submit'>
                            <RiDeleteBinLine /> Удалить
                        </Button>
                    </Modal.Footer>
                </RouterForm>
            </Modal>
        </>
    );
};

export default DeleteRoutineTask;
