import {
  Form as RouterForm,
  Link,
  useSearchParams,
  useNavigation,
} from 'react-router-dom';
import { useState } from 'react';

import AlertMessage from '../../UI/AlertMessage';

import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Signup = ({ data }) => {
  const [searchParams] = useSearchParams();
  const navigation = useNavigation();

  const isSignup = searchParams.get('mode') === 'signup';
  const isSubmitting = navigation.state === 'submitting';

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const firstNameChangeHandler = (event) => {
    setFirstName(event.target.value);
  };

  const lastNameChangeHandler = (event) => {
    setLastName(event.target.value);
  };

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const emailChangeHandler = (event) => {
    setEmail(event.target.value);
  };

  const passwordChangeHandler = (event) => {
    setPassword(event.target.value);
  };

  return (
    <>
      {isSignup && (
        <RouterForm method='post'>
          <Row className='justify-content-center mb-4'>
            <Col sm={6}>
              {data && data.errors && (
                <AlertMessage
                  variant='danger'
                  message={Object.values(data.errors).map((err) => (
                    <li key={err}>{err}</li>
                  ))}
                />
              )}
              {data && data.message && (
                <AlertMessage variant='danger' message={data.message} />
              )}
              <Form.Group className='mb-3'>
                <FloatingLabel label='Имя' className='mb-3'>
                  <Form.Control
                    required
                    placeholder='Имя'
                    type='text'
                    name='firstName'
                    value={firstName}
                    onChange={firstNameChangeHandler}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group className='mb-3'>
                <FloatingLabel label='Фамилия' className='mb-3'>
                  <Form.Control
                    required
                    placeholder='Фамилия'
                    type='text'
                    name='lastName'
                    value={lastName}
                    onChange={lastNameChangeHandler}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group className='mb-3'>
                <FloatingLabel label='E-Mail' className='mb-3'>
                  <Form.Control
                    required
                    type='email'
                    name='email'
                    placeholder='E-Mail'
                    value={email}
                    onChange={emailChangeHandler}
                  />
                  <Form.Text id='password' muted>
                    Обязательно указывайте рабочий адрес
                  </Form.Text>
                </FloatingLabel>
              </Form.Group>
              <Form.Group className='mb-3'>
                <FloatingLabel label='Пароль' className='mb-3'>
                  <Form.Control
                    required
                    id='password'
                    type='password'
                    name='password'
                    placeholder='Пароль'
                    value={password}
                    onChange={passwordChangeHandler}
                  />
                  <Form.Text id='password' muted>
                    Минимум 6 символов
                  </Form.Text>
                </FloatingLabel>
              </Form.Group>
              <Form.Group className='text-end'>
                <Link to='?mode=login' className='me-4'>
                  Войти
                </Link>
                
                <Button variant='primary' type='submit' disabled={isSubmitting}>
                  {isSubmitting ? 'Регистрация...' : 'Зарегистрироваться'}
                </Button>
              </Form.Group>
            </Col>
          </Row>
        </RouterForm>
      )}
    </>
  );
};

export default Signup;
