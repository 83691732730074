import { useState, useEffect } from "react";
import { redirect, useLoaderData } from "react-router-dom";

import { getLocalStorageData } from "../../util/auth";

import { RiServerLine } from "react-icons/ri";

import TicketCategoriesList from "../../components/TicketCategory/List";

import ListCardContent from "../../UI/ListCardContent";

const TicketCategories = () => {
  const categories = useLoaderData();

  const [filteredCategories, setFilteredCategories] = useState(categories);

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setFilteredCategories(
      categories.filter((category) =>
        category.title.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    );
  }, [searchTerm, categories]);

  useEffect(() => {
    setFilteredCategories(categories);
  }, [categories]);

  const title = () => {
    return (
      <>
        <RiServerLine /> Категории заявок
      </>
    );
  };

  return (
    <ListCardContent
      title={title}
      filteredList={filteredCategories}
      setSearchTerm={setSearchTerm}
    >
      <TicketCategoriesList items={filteredCategories}></TicketCategoriesList>
    </ListCardContent>
  );
};

export default TicketCategories;

export async function loader() {
  document.title = "КАТЕГОРИИ ЗАЯВОК";

  const { token } = getLocalStorageData();

  const response = await fetch(
    `${process.env.REACT_APP_ADDRESS}/api/ticket-categories`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  );
  if (!response.ok) {
    throw response;
  }

  return response;
}

export async function action({ request }) {
  const { token } = getLocalStorageData();

  const data = await request.formData();
  const id = data.get("id");

  const response = await fetch(
    `${process.env.REACT_APP_ADDRESS}/api/ticket-categories/delete/${id}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    },
  );

  if ([409].includes(response.status)) {
    return response;
  }

  if (!response.ok) {
    throw response;
  }

  return redirect("/ticket-categories");
}
