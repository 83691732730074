import { Outlet, useNavigation } from "react-router-dom";
import { Link } from "react-router-dom";

import { BrowserView, MobileView } from "react-device-detect";

import { RiRefreshLine } from "react-icons/ri";

import AddButton from "./AddButton";

import Transitions from "../animations/Transition";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import Spinner from "../animations/Spinner";
import AlertMessage from "./AlertMessage";

const ListCardContent = ({
  title,
  addRoute,
  filteredList = [],
  secondaryList = [],
  hideLengthRow = false,
  children,
}) => {
  const { state } = useNavigation();

  return (
    <>
      <Card.Title>
        <h1 className="display-4">{title()}</h1>
      </Card.Title>
      <hr></hr>
      <BrowserView>
        <Row>
          <Col sm="auto">
            <Button as={Link} replace to="." size="lg" className="w-100 mb-3">
              <RiRefreshLine /> Обновить
            </Button>
          </Col>
          <Col sm="auto">
            <AddButton content="Добавить" to={addRoute ? addRoute : "add"} />
          </Col>
        </Row>
      </BrowserView>
      <MobileView>
        <Row>
          <Col xs={6}>
            <Button as={Link} replace to="." size="lg" className="w-100">
              <RiRefreshLine /> Обновить
            </Button>
          </Col>
          <Col xs={6}>
            <AddButton content="Добавить" to={addRoute ? addRoute : "add"} />
          </Col>
        </Row>
      </MobileView>
      {(filteredList.length > 0 || secondaryList.length > 0) && (
        <>
          {state === "idle" && (
            <Transitions>
              {!hideLengthRow && (
                <Row className="justify-content-between text-secondary mb-1 mt-3">
                  <Col>{`Найдено: ${filteredList.length}`}</Col>
                </Row>
              )}

              {children}
            </Transitions>
          )}
          {state === "loading" && (
            <Transitions>
              <Spinner />
            </Transitions>
          )}
        </>
      )}
      {filteredList.length === 0 && secondaryList.length === 0 && (
        <AlertMessage variant="light" message="Список пуст" />
      )}
      <Outlet />
    </>
  );
};

export default ListCardContent;
