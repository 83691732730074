import { createContext } from "react";

export const SidebarContext = createContext({
  showLeftSidebar: false,
  setShowLeftSidebar: () => {
    return null;
  },
  leftSidebarContent: <></>,
  setLeftSidebarContent: () => {
    return null;
  },
});
