import { useState, useRef, useContext } from "react";
import { useDispatch } from "react-redux";
import pad from "pad";

import useHttp from "../../hooks/use-http";

import { changeTimezone } from "../../util/format-date";

import Select from "../../UI/Select";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";

import { toastActions } from "../../store/toast";
import { AuthedUserContext } from "../../store/authed-user-context";

import { RiEdit2Line } from "react-icons/ri";
import DeleteWork from "./Delete";
import { getLocalStorageData } from "../../util/auth";

const msToHMS = (ms) => {
  // 1- Convert to seconds:
  let seconds = ms / 1000;
  // 2- Extract hours:
  const hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
  seconds = seconds % 3600; // seconds remaining after extracting hours
  // 3- Extract minutes:
  const minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
  // 4- Keep only seconds not extracted to minutes:
  seconds = seconds % 60;

  const humanized =
    [pad(2, hours.toString(), "0"), pad(2, minutes.toString(), "0")].join(":") +
    " ч.";

  return humanized;
};

const UpdateScheduledWork = (props) => {
  const dispatch = useDispatch();
  const { token } = getLocalStorageData();
  const { isAdmin, _id: userId } = useContext(AuthedUserContext);

  const { work, otherCompanyTickets, ticket } = props;

  const usersList = props.responsibles;

  const [executor, setExecutor] = useState(work.executor);
  const [visitRequired, setVisitRequired] = useState(work.visitRequired);

  const planningToStartInputRef = useRef();
  const planningToFinishInputRef = useRef();

  const [linkToTickets, setLinkToTickets] = useState(
    otherCompanyTickets.filter((ticket) =>
      work.tickets?.includes(ticket._id.toString()),
    ),
  );

  const linkToTicketsHandler = (selectedItems) => {
    setLinkToTickets(selectedItems);
  };

  const setMe = () => {
    setExecutor(
      usersList.filter((user) => user._id.toString() === userId.toString())[0],
    );
  };

  const executorChangeHandler = (selectedItem) => {
    setExecutor(selectedItem);
  };

  const visitRequiredHandler = () => {
    setVisitRequired(!visitRequired);
  };

  const { sendRequest: postWorkHandler } = useHttp();

  const [minDate, setMinDate] = useState("");
  const minDateChangeHandler = (event) => {
    setMinDate(event.target.value);
    workDurationHandler();
  };

  const submitHandler = (event) => {
    event.preventDefault();

    const linkedTickets = linkToTickets.map((ticket) => ticket._id);
    linkedTickets.unshift(ticket._id);

    const submittedWork = {
      _id: props.work._id,
      tickets: linkedTickets,
      company: props.ticket.company?._id,
      ticketCategory: props.ticket.category,
      ticketApplicant: props.ticket.applicant,
      visitRequired: visitRequired,
      planningToStart: new Date(planningToStartInputRef.current.value),
      planningToFinish: new Date(planningToFinishInputRef.current.value),
      executor: isAdmin ? executor : userId,
    };

    const updateWork = (data) => {
      dispatch(
        toastActions.setState({
          variant: "success text-white",
          message: "Работа добавлена",
          show: true,
        }),
      );

      setShowUpdateModal(false);

      const updatedWork = {
        _id: data.work._id,
        tickets: data.work.tickets,
        description: data.work.description,
        visitRequired: data.work.visitRequired,
        planningToStart: data.work.planningToStart,
        planningToFinish: data.work.planningToFinish,
        executor: data.work.executor,
        createdBy: data.work.createdBy,
        createdAt: data.work.createdAt,
      };
      props.setWorks((items) => {
        return items.map((i) => (i._id === updatedWork._id ? updatedWork : i));
      });
    };

    postWorkHandler(
      {
        url: `${process.env.REACT_APP_ADDRESS}/api/works/update/`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: submittedWork,
      },
      (data) => {
        if (data.work) {
          updateWork(data);
        } else {
          dispatch(
            toastActions.setState({
              variant: "danger text-white",
              message: data.message,
              show: true,
            }),
          );
        }
      },
    );
  };

  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const handleCloseUpdateModal = () => setShowUpdateModal(false);
  const handleShowUpdateModal = () => setShowUpdateModal(true);

  function addMinutes(minutes) {
    const date = planningToFinishInputRef.current.value
      ? new Date(planningToFinishInputRef.current.value)
      : planningToStartInputRef.current.value
        ? new Date(planningToStartInputRef.current.value)
        : null;
    if (date) {
      planningToFinishInputRef.current.value = changeTimezone(
        new Date(date.getTime() + minutes * 60000),
      );
    } else {
      document.getElementById("planning-to-start").focus();
    }

    workDurationHandler();
    return;
  }

  const [workDuration, setWorkDuration] = useState(
    msToHMS(new Date(work.planningToFinish) - new Date(work.planningToStart)),
  );

  const workDurationHandler = () => {
    if (
      planningToFinishInputRef.current?.value &&
      planningToStartInputRef.current?.value
    ) {
      setWorkDuration(
        msToHMS(
          new Date(planningToFinishInputRef.current?.value || "") -
            new Date(planningToStartInputRef.current?.value || ""),
        ),
      );
    } else {
      setWorkDuration("00:00 ч.");
    }
  };

  return (
    <>
      <Button className="" onClick={handleShowUpdateModal}>
        <RiEdit2Line /> Изменить
      </Button>

      <Modal
        centered
        size="lg"
        show={showUpdateModal}
        onHide={handleCloseUpdateModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Изменить работы</Modal.Title>
        </Modal.Header>
        <Form onSubmit={submitHandler}>
          <Modal.Body>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                Требуется выезд
              </Form.Label>
              <Col sm="auto" className="mt-2">
                <Form.Check
                  checked={visitRequired}
                  value={visitRequired}
                  onChange={visitRequiredHandler}
                  type="switch"
                  name="visitRequired"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="4">
                Планируемое начало
              </Form.Label>
              <Col sm="auto" className="mb-3">
                <Form.Control
                  required
                  type="datetime-local"
                  defaultValue={changeTimezone(new Date(work.planningToStart))}
                  ref={planningToStartInputRef}
                  onChange={minDateChangeHandler}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="4">
                Планируемое окончание
              </Form.Label>
              <Col sm="auto" className="mb-3">
                <Form.Control
                  required
                  type="datetime-local"
                  onChange={workDurationHandler}
                  defaultValue={changeTimezone(new Date(work.planningToFinish))}
                  ref={planningToFinishInputRef}
                  min={minDate}
                />
              </Col>
              <Col xs="auto" className="mb-3">
                <Button
                  onClick={() => {
                    addMinutes(60);
                  }}
                >
                  +1 час
                </Button>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                Длительность
              </Form.Label>
              <Form.Label column sm="auto">
                <strong>{workDuration}</strong>
              </Form.Label>
            </Form.Group>
            {isAdmin && (
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  Исполнитель
                </Form.Label>
                <Col sm="7" className="mb-3">
                  <Select
                    isSearchable
                    required
                    value={executor}
                    options={usersList}
                    getOptionLabel={(option) =>
                      `${option.lastName} ${option.firstName}`
                    }
                    getOptionValue={(option) => option._id}
                    onChange={executorChangeHandler}
                  />
                </Col>
                <Col xs="auto">
                  <Button onClick={setMe}>Я</Button>
                </Col>
              </Form.Group>
            )}
            <Form.Group as={Row} className="mb-3">
              <Form.Label htmlFor="otherCompanyTickets" column sm="4">
                Также привязать к
              </Form.Label>
              <Col sm="8" className="mb-3">
                <Select
                  id="otherCompanyTickets"
                  placeholder="Выберите заявки"
                  closeMenuOnSelect={false}
                  isClearable
                  isSearchable
                  isMulti
                  value={linkToTickets}
                  options={otherCompanyTickets}
                  getOptionLabel={(option) => `${option.num} / ${option.title}`}
                  getOptionValue={(option) => option._id}
                  onChange={linkToTicketsHandler}
                />
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <DeleteWork
              work={work}
              works={props.works}
              setWorks={props.setWorks}
              setShowUpdateModal={setShowUpdateModal}
            />
            <Button variant="secondary" onClick={handleCloseUpdateModal}>
              Закрыть
            </Button>
            <Button type="submit" variant="primary">
              Изменить
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateScheduledWork;
