import { useState, useEffect, useCallback, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";

import { MobileView } from "react-device-detect";

import { userFiltersActions } from "../../store/filter-users";

import { Row, Col, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import SearchBar from "../../UI/SearchBar";

import { AuthedUserContext } from "../../store/authed-user-context";

const UserFilter = ({
  users,
  setFilteredUsers,
  setShowOffcanvas = () => {
    return null;
  },
}) => {
  const dispatch = useDispatch();

  const {
    isAdmin,
    permissions,
    role: userRole,
    _id: userId,
  } = useContext(AuthedUserContext);

  const [filter, setFilter] = useState(
    useSelector((state) => state.usersFilter || { companies: [] }),
  );

  // Получаем список компаний из всего списка заявок и исключаем дублирование
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    let array = [];
    users.forEach((user) => {
      if (
        !array
          .map((company) => company._id.toString())
          .includes(user.company?._id.toString())
      ) {
        array.push({
          _id: user.company?._id.toString(),
          alias: user.company?.alias,
        });
      }
    });
    setCompanies(array.sort((a, b) => a.alias.localeCompare(b.alias)));
  }, [users]);

  const companyToggleHandler = (event) => {
    const value = event.target.value;

    setFilter({
      ...filter,
      companies: !filter.companies?.includes(value)
        ? [...filter.companies, value]
        : filter.companies?.filter((company) => company !== value),
    });
  };

  const searchItems = (query, items) => {
    if (!query) return items;

    const queryTerms = query.toLowerCase().split(" ").filter(Boolean);

    return items.filter((item) => {
      const fieldsToSearch = [
        item.company?.alias,
        item.email,
        item.phone,
        `${item.firstName} ${item.lastName}`,
        item.firstName,
        item.lastName,
        item.position,
        item.role,
        //...item.categories.flatMap((category) => [`${category.title}`]),
      ];

      return queryTerms.every((term) =>
        fieldsToSearch.some(
          (field) => field && field.toLowerCase().includes(term),
        ),
      );
    });
  };

  // Поиск по тексту
  const [searchTerm, setSearchTerm] = useState("");

  const searchHandler = (e) => {
    const query = e.target.value;
    setSearchTerm(searchTerm);
    setFilteredUsers(searchItems(query, userFilter(users)));
  };

  // функция последовательно отсеивает заявки согласно активным фильтрам
  const userFilter = useCallback(
    (users) => {
      return users
        .filter((user) => {
          if (filter.companies?.length > 0) {
            return filter.companies?.includes(user.company?._id.toString());
          } else {
            return true;
          }
        })
        .filter((user) => {
          if (searchTerm.length > 0) {
            return [
              user.lastName,
              user.firstName,
              user.email,
              user.phone,
              user.position,
              user.role,
              user.company?.alias,
              JSON.stringify(user.categories),
            ]
              .join(" ")
              .toLowerCase()
              .includes(searchTerm);
          } else {
            return true;
          }
        });
    },
    [filter, searchTerm],
  );

  useEffect(() => {
    // изменяем список заявок в соответствии со значениями объекта filter
    setFilteredUsers(userFilter(users));
    // индикатор активности доп. фильтров
    dispatch(userFiltersActions.setState(filter));
  }, [filter, searchTerm, setFilteredUsers, dispatch, users, userFilter]);

  const resetFilterHandler = () => {
    setFilter({
      companies: [],
    });
  };

  const getListLengthBy = (list, itemName, item) => {
    let result = [];
    if (itemName === "company") {
      result = list.filter((user) => user.company?._id === item._id).length;
    }

    return result;
  };

  return (
    <div className="pb-2">
      <h3>Фильтр</h3>
      <Row className="py-2 border-top border-bottom">
        <Col fluid="true">
          <SearchBar onChange={searchHandler} size="md" />
        </Col>
      </Row>
      {userRole !== "Клиент" && (
        <>
          <Row className="py-2 border-bottom">
            <Col>
              <h5>
                <strong>Компании</strong>
              </h5>
              {companies.map((company) => {
                return (
                  <Form.Check
                    key={company._id}
                    label={`${company.alias} (${getListLengthBy(userFilter(users), "company", company)})`}
                    className={`
                    ${filter.companies?.includes(company._id) ? "text-info" : ""}
                    ${
                      getListLengthBy(userFilter(users), "company", company) ===
                      0
                        ? "text-secondary"
                        : ""
                    } py-2`}
                    value={company._id}
                    id={`company-${company._id}`}
                    checked={filter.companies?.includes(company._id)}
                    type="checkbox"
                    name="filter-group-companies"
                    onChange={companyToggleHandler}
                  />
                );
              })}
            </Col>
          </Row>
          <Row className="py-2 border-bottom gap-3">
            <MobileView>
              <Col>
                <Button
                  className="w-100"
                  onClick={() => {
                    setShowOffcanvas(false);
                  }}
                >
                  Применить
                </Button>
              </Col>
            </MobileView>
            <Col>
              <Button
                className="w-100"
                variant="warning"
                onClick={resetFilterHandler}
              >
                Сбросить
              </Button>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};
export default UserFilter;
