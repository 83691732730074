import { useState, useEffect } from "react";
import { redirect, useLoaderData } from "react-router-dom";

import { RiCalendar2Line } from "react-icons/ri";

import RoutineTasksList from "../../components/RoutineTask/List";

import { getLocalStorageData } from "../../util/auth";

import ListCardContent from "../../UI/ListCardContent";

const RoutineTasks = () => {
  const tasks = useLoaderData();

  const [filteredTasks, setFilteredTasks] = useState(tasks);

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setFilteredTasks(
      tasks.filter(
        (task) =>
          task.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          task.company.alias
            .toLowerCase()
            .includes(searchTerm.toLocaleLowerCase()),
      ),
    );
  }, [searchTerm, tasks]);

  useEffect(() => {
    setFilteredTasks(tasks);
  }, [tasks]);

  const title = () => {
    return (
      <>
        <RiCalendar2Line /> Регламентные задания
      </>
    );
  };

  return (
    <ListCardContent
      title={title}
      filteredList={filteredTasks}
      setSearchTerm={setSearchTerm}
    >
      <RoutineTasksList items={filteredTasks}></RoutineTasksList>
    </ListCardContent>
  );
};

export default RoutineTasks;

export async function loader() {
  document.title = "РЕГЛАМЕНТНЫЕ ЗАДАНИЯ";

  const { token } = getLocalStorageData();

  const response = await fetch(
    `${process.env.REACT_APP_ADDRESS}/api/routine-tasks`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  );
  if (!response.ok) {
    throw response;
  }

  return response;
}

export async function action({ request }) {
  const { token } = getLocalStorageData();

  const data = await request.formData();
  const id = data.get("id");

  const response = await fetch(
    `${process.env.REACT_APP_ADDRESS}/api/routine-tasks/delete/${id}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    },
  );

  if ([409].includes(response.status)) {
    return response;
  }

  if (!response.ok) {
    throw response;
  }

  return redirect("/routine-tasks");
}
