import { useState } from 'react';

import Card from 'react-bootstrap/Card';

const ItemCard = (props) => {
    const [isNew, setIsNew] = useState(
        new Date() - new Date(props.createdAt) < 10000 ? true : false
    );

    setTimeout(() => {
        setIsNew(false);
    }, 15000); 

    return (
        <Card
            className={`mb-3 shadow-sm ${
                isNew
                    ? 'bg-success bg-opacity-10'
                    : props.danger
                    ? 'bg-danger bg-opacity-10'
                    : ''
            }`}
        >
            <Card.Body>{props.children}</Card.Body>
        </Card>
    );
};

export default ItemCard;
