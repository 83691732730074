import { useState } from "react";

import Form from "react-bootstrap/Form";

const PrefsTimings = (props) => {
  const [deadline, setDeadline] = useState(props.prefs.deadline);

  const deadlineChangeHandler = (event) => {
    setDeadline(event.target.value);
    props.prefs.deadline = event.target.value;
  };

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>Дедлайн, часы</Form.Label>
        <Form.Control
          type="number"
          value={deadline}
          onChange={deadlineChangeHandler}
        />
      </Form.Group>
    </>
  );
};

export default PrefsTimings;
