import CompanyItem from './Item';

const CompaniesList = (props) => {
    return (
        <>
            {props.items.map((company) => (
                <CompanyItem
                    key={company._id.toString()}
                    company={company}
                    setCompaniesList={props.setCompaniesList}
                    responsiblesList={props.responsiblesList}
                />
            ))}
        </>
    );
};

export default CompaniesList;
