import RoutineTaskItem from './Item';

const RoutineTasksList = (props) => {
    return (
        <>
            {props.items.map((task) => (
                <RoutineTaskItem
                    key={task._id.toString()}
                    task={task}
                    setList={props.setList}
                />
            ))}
        </>
    );
};

export default RoutineTasksList;
