import { redirect } from "react-router-dom";

import CompanyForm from "../../components/Company/Form";
import { getLocalStorageData } from "../../util/auth";

const AddCompanyPage = () => {
  return <CompanyForm title="Новая компания" />;
};

export default AddCompanyPage;

export async function loader() {
  document.title = "НОВАЯ КОМПАНИЯ";

  const { token } = getLocalStorageData();

  const response = await fetch(
    `${process.env.REACT_APP_ADDRESS}/api/users/responsibles`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  );

  if (!response.ok) {
    throw response;
  }

  return {
    responsibles: await response.json(),
  };
}

export async function action({ request }) {
  const { token } = getLocalStorageData();

  const data = await request.formData();

  const companyData = {
    alias: data.get("alias"),
    fullTitle: data.get("fullTitle"),
    emailDomains: data.get("emailDomains"),
    phones: data.get("phones"),
    responsibles: data.getAll("responsibles"),
  };

  const response = await fetch(
    `${process.env.REACT_APP_ADDRESS}/api/companies/add`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(companyData),
    },
  );

  if (!response.ok) {
    throw response;
  }

  return redirect("/companies");
}
