import ItemCard from '../../UI/ItemCard';

import DeleteTicketCategory from './Delete';
import Button from 'react-bootstrap/esm/Button';
import { Link } from 'react-router-dom';

import { RiEdit2Line } from 'react-icons/ri';

function TicketCategoryItem(props) {
    const { createdAt, title, isActive, _id } = props.category;
    return (
        <ItemCard createdAt={createdAt}>
            <h4>{title}</h4>
            <p>Активна: {isActive ? 'да' : 'нет'}</p>
            <Button as={Link} to={`update/${_id}`}>
                <RiEdit2Line /> Изменить
            </Button>
            <DeleteTicketCategory
                category={props.category}
                setList={props.setList}
            />
        </ItemCard>
    );
}

export default TicketCategoryItem;
