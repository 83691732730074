import {
    useNavigate,
    Form as RouterForm,
    useActionData,
    useNavigation,
} from 'react-router-dom';
import { useState } from 'react';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import AlertMessage from '../../UI/AlertMessage';

import { RiSaveLine } from 'react-icons/ri';

const AddChangelog = (props) => {
    const navigate = useNavigate();
    const { state } = useNavigation();
    const data = useActionData();

    const [enteredTitle, setEnteredTitle] = useState('');

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [text, setText] = useState('');
    const onEditorStateChange = function (editorState) {
        setEditorState(editorState);
        let text = convertToHTML(editorState.getCurrentContent());
        setText(text);
    };

    const [isPublic, setIsPublic] = useState(false);

    const titleChangeHandler = (event) => {
        setEnteredTitle(event.target.value);
    };

    const isPublicChangeHandler = () => {
        setIsPublic(!isPublic);
    };

    return (
        <>
            <Modal
                show={true}
                size='xl'
                onHide={() => {
                    navigate('/changelog');
                }}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Новая запись</Modal.Title>
                </Modal.Header>

                <RouterForm method='post'>
                    
                    <Modal.Body>
                        {data && data.errors && (
                            <AlertMessage
                                variant='danger'
                                message={Object.values(data.errors).map(
                                    (err) => (
                                        <li key={err}>{err}</li>
                                    )
                                )}
                            />
                        )}
                        {data && data.message && (
                            <AlertMessage
                                variant='danger'
                                message={data.message}
                            />
                        )}
                        <Form.Group className='mb-3'>
                            <Form.Label htmlFor='title'>
                                Заголовок
                                <span style={{ color: 'red' }}>*</span>
                            </Form.Label>
                            <Form.Control
                                required
                                autoFocus
                                id='title'
                                name='title'
                                type='text'
                                value={enteredTitle}
                                onChange={titleChangeHandler}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label htmlFor='body'>Описание</Form.Label>
                            <Form.Control
                                as={Editor}
                                id='body'
                                name='body'
                                editorState={editorState}
                                onEditorStateChange={onEditorStateChange}
                            />
                            <Form.Control
                                hidden={true}
                                id='body'
                                name='body'
                                value={text}
                                onChange={setText}
                            />
                        </Form.Group>
                        <Form.Check
                            checked={isPublic}
                            type='switch'
                            id='isPublic'
                            name='isPublic'
                            label='для всех (будет отображаться у Клиентов)'
                            value={isPublic}
                            onChange={isPublicChangeHandler}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            onClick={() => {
                                navigate('/changelog');
                            }}
                        >
                            Закрыть
                        </Button>
                        <Button
                            variant='primary'
                            type='submit'
                            disabled={state === 'submitting'}
                        >
                            <RiSaveLine />{' '}
                            {state === 'submitting'
                                ? 'Сохранение...'
                                : 'Добавить'}
                        </Button>
                    </Modal.Footer>
                </RouterForm>
            </Modal>
        </>
    );
};

export default AddChangelog;
