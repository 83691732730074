import { Link, useNavigate } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';
import Accordion from 'react-bootstrap/Accordion';
import Alert from 'react-bootstrap/Alert';

import Transitions from '../../animations/Transition';

import DeleteCompany from '../../components/Company/Delete';

import { RiEdit2Line, RiArrowGoBackFill } from 'react-icons/ri';

const ViewUser = (props) => {
    const navigate = useNavigate();

    const { company } = props;

    return (
        <>
            <Transitions>
                <Row className='justify-content-md-end mb-3'>
                    <Col sm={2} className='mb-3'>
                        <Image
                            src='/companypic-placeholder.png'
                            roundedCircle
                        />
                    </Col>
                    <Col>
                        <h3>{company.alias}</h3>
                        <p className='lead'>{company.fullTitle}</p>
                    </Col>
                </Row>
                <Row className='mb-2'>
                    <Col sm='6'>
                        <h4>Контакты</h4>
                        <Table>
                            <tbody>
                                <tr>
                                    <th>Телефон</th>
                                    <td>
                                        {company.phones.map((phone) => (
                                            <h6 key={phone}>
                                                <a href={'tel:' + phone}>
                                                    {phone}
                                                </a>
                                            </h6>
                                        ))}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Почтовые домены</th>
                                    <td>
                                        {company.emailDomains.map((domain) => (
                                            <h6 key={domain}>{domain}</h6>
                                        ))}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row className='mb-2'>
                    <Col sm='6'></Col>
                </Row>
                <Row className='mb-2'>
                    <Col className='mb-2'>
                        <h4>Ответственные</h4>
                        {company.responsibles.map((resp) => {
                            return (
                                <Badge key={resp._id} className='mx-1'>
                                    {`${resp.lastName} ${resp.firstName}`}
                                </Badge>
                            );
                        })}
                    </Col>
                </Row>
                <Row className='mb-2'>
                    <Col className='mb-2'>
                        <h4>Пользователи</h4>
                        {company.users.length > 0 && (
                            <Accordion className='mb-2'>
                                <Accordion.Item eventKey='0'>
                                    <Accordion.Header>{`Раскрыть список (${company.users.length} записей)`}</Accordion.Header>
                                    <Accordion.Body>
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th>Имя</th>
                                                    <th>Email</th>
                                                    <th>Телефон</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {company.users.map((user) => {
                                                    return (
                                                        <tr key={user._id}>
                                                            <td>
                                                                <Link
                                                                    to={
                                                                        '/users/' +
                                                                        user._id
                                                                    }
                                                                    target='_blank'
                                                                >
                                                                    {
                                                                        user.fullName
                                                                    }
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                {user.email}
                                                            </td>
                                                            <td>
                                                                {user.phone}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        )}
                        {company.users.length === 0 && (
                            <Alert
                                variant='light'
                                className='text-centered mb-2'
                            >
                                Пользователи не найдены
                            </Alert>
                        )}
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col sm='auto'>
                        <Button
                            onClick={() => navigate('/companies')}
                            className='mb-2 w-100'
                        >
                            <RiArrowGoBackFill /> К списку
                        </Button>
                    </Col>
                    <Col sm='auto'>
                        <Button as={Link} to={`update`} className='mb-2 w-100'>
                            <RiEdit2Line /> Изменить
                        </Button>
                    </Col>
                    <Col sm='auto'>
                        <DeleteCompany company={company} />
                    </Col>
                </Row>
            </Transitions>
        </>
    );
};

export default ViewUser;
