import { useRef, useState, useContext } from "react";
import { useFetcher } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import FormControl from "react-bootstrap/FormControl";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";

import { RiCheckboxCircleLine } from "react-icons/ri";

import { AuthedUserContext } from "../../../store/authed-user-context";

const CloseTicket = (props) => {
  const fetcher = useFetcher();

  const {
    role: userRole,
    permissions,
    _id: userId,
  } = useContext(AuthedUserContext);
  const { canAvoidWorks } = permissions;

  // modal handling
  const [show, setShow] = useState(false);
  const showModal = () => {
    setShow(true);
  };
  const closeModal = () => {
    setShow(false);
  };

  const closingComment = useRef();

  const submitHandler = (event) => {
    event.preventDefault();

    fetcher.submit(
      {
        intent: "close",
        _id: props.ticket._id,
        closingComment: closingComment.current.value,
      },
      {
        method: "POST",
        action: `/tickets/${props.ticket.num}`,
      },
    );
    closeModal();
  };

  const uncheckedChecklistItems =
    props.ticket.checklist?.filter((item) => item.checked === false).length > 0;

  return (
    <>
      {userRole !== "Клиент" &&
        props.ticket.state === "В работе" &&
        props.ticket.responsibles
          .map((user) => user._id.toString())
          .includes(userId) && (
          <>
            <Col sm="auto">
              {(props.closeTicketIsActive || canAvoidWorks) && (
                <Button
                  variant="success"
                  size="lg"
                  className="w-100 mb-2"
                  onClick={showModal}
                  disabled={fetcher.state !== "idle"}
                >
                  <strong>
                    <RiCheckboxCircleLine /> Закрыть заявку
                  </strong>
                </Button>
              )}

              {!props.closeTicketIsActive && !canAvoidWorks && (
                <Button
                  variant="warning"
                  size="lg"
                  disabled
                  className="w-100 mb-2"
                >
                  <strong>Укажите работы</strong>
                </Button>
              )}
            </Col>

            <Modal show={show} onHide={closeModal} centered>
              <Modal.Header closeButton>
                <Modal.Title>Закрыть заявку</Modal.Title>
              </Modal.Header>
              <Form onSubmit={submitHandler}>
                {uncheckedChecklistItems && (
                  <>
                    <Modal.Body>
                      <Alert variant="warning">
                        В чеклисте есть неотмеченные пункты. Убедитесь, что по
                        данной заявке выполнены все задачи, прежде чем закрыть
                        её.
                      </Alert>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        disabled={fetcher.state !== "idle"}
                        onClick={closeModal}
                      >
                        Закрыть
                      </Button>
                    </Modal.Footer>
                  </>
                )}
                {!uncheckedChecklistItems && (
                  <>
                    <Modal.Body>
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="description">
                          Результат выполнения
                        </Form.Label>
                        <Form.Group>
                          <FormControl
                            as="textarea"
                            placeholder='Например, "Добрый день! Проблема с Вашим компьютером устранена. Обновили операционную систему."'
                            required
                            rows={3}
                            ref={closingComment}
                          />
                        </Form.Group>
                      </Form.Group>
                      <Alert variant="warning">
                        Это сообщение будет отправлено инициатору заявки.
                      </Alert>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        disabled={fetcher.state !== "idle"}
                        onClick={closeModal}
                      >
                        Закрыть
                      </Button>
                      <Button type="submit" disabled={fetcher.state !== "idle"}>
                        Подтвердить
                      </Button>
                    </Modal.Footer>
                  </>
                )}
              </Form>
            </Modal>
          </>
        )}
    </>
  );
};

export default CloseTicket;
