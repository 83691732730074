import { Link } from 'react-router-dom';

import DeleteRoutineTask from './Delete';

import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ItemCard from '../../UI/ItemCard';

import { RiEdit2Line, RiBuilding2Line } from 'react-icons/ri';

function RoutineTaskItem(props) {
    return (
        <ItemCard createdAt={props.task.createdAt}>
            <h4>
                <Row>
                    <Col sm='auto'>{props.task.title}</Col>
                    <Col sm='auto'>
                        <Badge className='mb-2 w-100' bg='secondary'>
                            <RiBuilding2Line /> {props.task.company?.alias}
                        </Badge>
                    </Col>
                </Row>
            </h4>
            <p>Активно: {props.task.isActive ? 'да' : 'нет'}</p>
            <p>Категория: {props.task.category.title}</p>
            <p>Расписание cron: {props.task.cronSchedule}</p>
            <Button as={Link} to={`update/${props.task._id}`}>
                <RiEdit2Line /> Изменить
            </Button>
            <DeleteRoutineTask
                routineTask={props.task}
                setList={props.setList}
            />
        </ItemCard>
    );
}

export default RoutineTaskItem;
