import { createSlice } from "@reduxjs/toolkit";

const initialUserFilterState = {
  companies: [],
};

const userFiltersSlice = createSlice({
  name: "usersFilter",
  initialState: initialUserFilterState,
  reducers: {
    setState(state, action) {
      state.companies = action.payload.companies;
    },
  },
});

export const userFiltersActions = userFiltersSlice.actions;

export default userFiltersSlice;
