import { useRef, useState } from "react";
import { useFetcher } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import FormControl from "react-bootstrap/FormControl";

import { RiArrowGoBackFill } from "react-icons/ri";

const BackToWork = (props) => {
  const fetcher = useFetcher();

  // modal handling
  const [show, setShow] = useState(false);

  const showModal = () => {
    setShow(true);
  };
  const closeModal = () => {
    setShow(false);
  };

  const returningComment = useRef();

  const submitHandler = (event) => {
    event.preventDefault();

    fetcher.submit(
      {
        intent: "backToWork",
        _id: props.ticket._id,
        returningComment: returningComment.current.value,
      },
      {
        method: "POST",
        action: `/tickets/${props.ticket.num}`,
      },
    );

    closeModal();
  };

  return (
    <>
      {(props.ticket.state === "Закрыта" ||
        props.ticket.state === "Выполнена") && (
        <>
          <>
            <Col sm="auto">
              <Button
                className="mb-3 w-100"
                variant="warning"
                size="lg"
                onClick={showModal}
              >
                <strong>
                  <RiArrowGoBackFill /> Вернуть в работу
                </strong>
              </Button>
            </Col>
            <Modal show={show} onHide={closeModal} centered>
              <Modal.Header closeButton>
                <Modal.Title>Вернуть заявку в работу</Modal.Title>
              </Modal.Header>
              <Form onSubmit={submitHandler}>
                <Modal.Body>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="description">
                      Причина возврата в работу
                    </Form.Label>
                    <Form.Group>
                      <FormControl
                        as="textarea"
                        required
                        rows={2}
                        ref={returningComment}
                      />
                    </Form.Group>
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={closeModal}
                    disabled={fetcher.state !== "idle"}
                  >
                    Закрыть
                  </Button>
                  <Button type="submit" disabled={fetcher.state !== "idle"}>
                    Подтвердить
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          </>
        </>
      )}
    </>
  );
};

export default BackToWork;
