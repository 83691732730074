import { useState, useRef, useContext } from "react";
import { useDispatch } from "react-redux";
import pad from "pad";

import useHttp from "../../hooks/use-http";

import { changeTimezone } from "../../util/format-date";

import Select from "../../UI/Select";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";

import { GiConfirmed } from "react-icons/gi";

import { toastActions } from "../../store/toast";
import { AuthedUserContext } from "../../store/authed-user-context";
import { getLocalStorageData } from "../../util/auth";

const msToHMS = (ms) => {
  // 1- Convert to seconds:
  let seconds = ms / 1000;
  // 2- Extract hours:
  const hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
  seconds = seconds % 3600; // seconds remaining after extracting hours
  // 3- Extract minutes:
  const minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
  // 4- Keep only seconds not extracted to minutes:
  seconds = seconds % 60;

  const humanized =
    [pad(2, hours.toString(), "0"), pad(2, minutes.toString(), "0")].join(":") +
    " ч.";

  return humanized;
};

const ConfirmScheduledWork = ({
  ticket,
  responsibles,
  work,
  setWorks,
  otherCompanyTickets,
}) => {
  const dispatch = useDispatch();
  const { token } = getLocalStorageData();
  const {
    isAdmin,
    _id: userId,
    lastName,
    firstName,
  } = useContext(AuthedUserContext);

  const usersList = responsibles;

  const [description, setDescription] = useState("");
  const [finishedBy, setFinishedBy] = useState(work.executor);
  const [asPlanned, setAsPlanned] = useState(true);
  const [visitRequired, setVisitRequired] = useState(work.visitRequired);

  const startedAtInputRef = useRef();
  const finishedAtInputRef = useRef();

  const [linkToTickets, setLinkToTickets] = useState(
    otherCompanyTickets.filter((ticket) =>
      work.tickets?.includes(ticket._id.toString()),
    ),
  );

  const linkToTicketsHandler = (selectedItems) => {
    setLinkToTickets(selectedItems);
  };

  const setMe = () => {
    setFinishedBy(
      usersList.filter((user) => user._id.toString() === userId.toString())[0],
    );
  };

  const descriptionChangeHandler = (event) => {
    setDescription(event.target.value);
  };

  const finishedByChangeHandler = (selectedItem) => {
    setFinishedBy(selectedItem);
  };

  const asPlannedHandler = () => {
    setAsPlanned(!asPlanned);
  };

  const visitRequiredHandler = () => {
    setVisitRequired(!visitRequired);
  };

  const { sendRequest: postWorkHandler } = useHttp();

  const [minDate, setMinDate] = useState("");
  const minDateChangeHandler = (event) => {
    setMinDate(event.target.value);
    workDurationHandler();
  };

  const submitHandler = (event) => {
    event.preventDefault();

    const linkedTickets = linkToTickets.map((ticket) => ticket._id);
    linkedTickets.unshift(ticket._id);

    const submittedWork = {
      _id: work._id,
      tickets: linkedTickets,
      company: ticket.company?._id,
      ticketCategory: ticket.category,
      ticketApplicant: ticket.applicant,
      description: description,
      visitRequired: asPlanned ? work.visitRequired : visitRequired,
      startedAt: asPlanned
        ? new Date(work.planningToStart)
        : new Date(startedAtInputRef.current.value),
      finishedAt: asPlanned
        ? new Date(work.planningToFinish)
        : new Date(finishedAtInputRef.current.value),
      finishedBy: asPlanned
        ? work.executor
        : isAdmin
          ? finishedBy
          : {
              _id: userId,
              firstName: firstName,
              lastName: lastName,
            },
    };

    const updateWork = (data) => {
      dispatch(
        toastActions.setState({
          variant: "success text-white",
          message: "Работа добавлена",
          show: true,
        }),
      );

      setShowUpdateModal(false);

      const updatedWork = {
        _id: data.work._id,
        tickets: data.work.tickets,
        description: data.work.description,
        visitRequired: data.work.visitRequired,
        startedAt: data.work.startedAt,
        finishedAt: data.work.finishedAt,
        finishedBy: data.work.finishedBy,
        createdBy: data.work.createdBy,
        createdAt: data.work.createdAt,
      };
      setWorks((items) => {
        return items.map((i) => (i._id === updatedWork._id ? updatedWork : i));
      });
    };

    postWorkHandler(
      {
        url: `${process.env.REACT_APP_ADDRESS}/api/works/update/`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: submittedWork,
      },
      (data) => {
        if (data.work) {
          updateWork(data);
        } else {
          dispatch(
            toastActions.setState({
              variant: "danger text-white",
              message: data.message,
              show: true,
            }),
          );
        }
      },
    );
  };

  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const handleCloseUpdateModal = () => setShowUpdateModal(false);
  const handleShowUpdateModal = () => setShowUpdateModal(true);

  function addMinutes(minutes) {
    const date = finishedAtInputRef.current.value
      ? new Date(finishedAtInputRef.current.value)
      : new Date();
    finishedAtInputRef.current.value = changeTimezone(
      new Date(date.getTime() + minutes * 60000),
    );
    workDurationHandler();
    return;
  }

  function substractMinutes(minutes) {
    const date = startedAtInputRef.current.value
      ? new Date(startedAtInputRef.current.value)
      : new Date();
    startedAtInputRef.current.value = changeTimezone(
      new Date(date.getTime() - minutes * 60000),
    );
    workDurationHandler();
    return;
  }

  const startedNowHandler = () => {
    startedAtInputRef.current.value = changeTimezone(new Date());
    workDurationHandler();
  };

  const finishedNowHandler = () => {
    finishedAtInputRef.current.value = changeTimezone(new Date());
    workDurationHandler();
  };

  const [workDuration, setWorkDuration] = useState("00:00 ч.");

  const workDurationHandler = () => {
    if (finishedAtInputRef.current?.value && startedAtInputRef.current?.value) {
      setWorkDuration(
        msToHMS(
          new Date(finishedAtInputRef.current?.value || "") -
            new Date(startedAtInputRef.current?.value || ""),
        ),
      );
    } else {
      setWorkDuration("00:00 ч.");
    }
  };

  return (
    <>
      <Button className="" onClick={handleShowUpdateModal}>
        <GiConfirmed /> Подтвердить
      </Button>

      <Modal
        centered
        size="lg"
        show={showUpdateModal}
        onHide={handleCloseUpdateModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Подтвердить работы</Modal.Title>
        </Modal.Header>
        <Form onSubmit={submitHandler}>
          <Modal.Body>
            <Form.Group as={Row}>
              <Form.Label column sm="2">
                Описание
              </Form.Label>
              <Col sm="9" className="mb-3">
                <Form.Control
                  required
                  value={description}
                  onChange={descriptionChangeHandler}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="2">
                По плану
              </Form.Label>
              <Col sm="auto" className="mt-2">
                <Form.Check
                  checked={asPlanned}
                  onChange={asPlannedHandler}
                  type="switch"
                />
              </Col>
            </Form.Group>
            {asPlanned && (
              <>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Выезд
                  </Form.Label>
                  <Col sm="auto" className="mt-2">
                    <Form.Check
                      disabled
                      checked={visitRequired}
                      type="switch"
                      name="visitRequired"
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Начало
                  </Form.Label>
                  <Col sm="auto" className="mb-3">
                    <Form.Control
                      disabled
                      type="datetime-local"
                      value={changeTimezone(new Date(work.planningToStart))}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Окончание
                  </Form.Label>
                  <Col sm="auto" className="mb-3">
                    <Form.Control
                      disabled
                      type="datetime-local"
                      value={changeTimezone(new Date(work.planningToFinish))}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Длительность
                  </Form.Label>
                  <Form.Label column sm="10">
                    <strong>
                      {msToHMS(
                        new Date(work.planningToFinish) -
                          new Date(work.planningToStart),
                      )}
                    </strong>
                  </Form.Label>
                </Form.Group>
                {isAdmin && (
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      Исполнитель
                    </Form.Label>
                    <Col sm="9">
                      <Select
                        isDisabled
                        value={work.executor}
                        options={usersList}
                        getOptionLabel={(option) =>
                          `${option.lastName} ${option.firstName}`
                        }
                        getOptionValue={(option) => option._id}
                      />
                    </Col>
                  </Form.Group>
                )}
              </>
            )}
            {!asPlanned && (
              <>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Выезд
                  </Form.Label>
                  <Col sm="auto" className="mt-2">
                    <Form.Check
                      checked={visitRequired}
                      value={visitRequired}
                      onChange={visitRequiredHandler}
                      type="switch"
                      name="visitRequired"
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Начало
                  </Form.Label>
                  <Col sm="auto" className="mb-3">
                    <Form.Control
                      required
                      type="datetime-local"
                      ref={startedAtInputRef}
                      onChange={minDateChangeHandler}
                    />
                  </Col>
                  <Col xs="auto" className="mb-3">
                    <Button onClick={startedNowHandler}>Сейчас</Button>
                  </Col>
                  <Col xs="auto">
                    <Button
                      onClick={() => {
                        substractMinutes(10);
                      }}
                    >
                      -10 мин
                    </Button>
                  </Col>
                  <Col xs="auto">
                    <Button
                      onClick={() => {
                        substractMinutes(30);
                      }}
                    >
                      -30 мин
                    </Button>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Окончание
                  </Form.Label>
                  <Col sm="auto" className="mb-3">
                    <Form.Control
                      required
                      type="datetime-local"
                      onChange={workDurationHandler}
                      ref={finishedAtInputRef}
                      min={minDate}
                    />
                  </Col>
                  <Col xs="auto" className="mb-3">
                    <Button onClick={finishedNowHandler}>Сейчас</Button>
                  </Col>
                  <Col xs="auto">
                    <Button
                      onClick={() => {
                        addMinutes(10);
                      }}
                    >
                      +10 мин
                    </Button>
                  </Col>
                  <Col xs="auto">
                    <Button
                      onClick={() => {
                        addMinutes(30);
                      }}
                    >
                      +30 мин
                    </Button>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    Длительность
                  </Form.Label>
                  <Form.Label column sm="10">
                    <strong>{workDuration}</strong>
                  </Form.Label>
                </Form.Group>
                {isAdmin && (
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      Исполнитель
                    </Form.Label>
                    <Col sm="9" className="mb-3">
                      <Select
                        isSearchable
                        required
                        value={finishedBy}
                        options={usersList}
                        getOptionLabel={(option) =>
                          `${option.lastName} ${option.firstName}`
                        }
                        getOptionValue={(option) => option._id}
                        onChange={finishedByChangeHandler}
                      />
                    </Col>
                    <Col xs="auto">
                      <Button onClick={setMe}>Я</Button>
                    </Col>
                  </Form.Group>
                )}
                <Form.Group as={Row} className="mb-3">
                  <Form.Label htmlFor="otherCompanyTickets" column sm="4">
                    Также привязать к
                  </Form.Label>
                  <Col sm="8" className="mb-3">
                    <Select
                      id="otherCompanyTickets"
                      placeholder="Выберите заявки"
                      closeMenuOnSelect={false}
                      isClearable
                      isSearchable
                      isMulti
                      value={linkToTickets}
                      options={otherCompanyTickets}
                      getOptionLabel={(option) =>
                        `${option.num} / ${option.title}`
                      }
                      getOptionValue={(option) => option._id}
                      onChange={linkToTicketsHandler}
                    />
                  </Col>
                </Form.Group>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseUpdateModal}>
              Закрыть
            </Button>
            <Button type="submit" variant="primary">
              Подтвердить
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ConfirmScheduledWork;
