import { useState, useRef, useCallback, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";

import { changeTimezone } from "../../util/format-date";

import Select from "../../UI/Select";

import useHttp from "../../hooks/use-http";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";

import WysiwygDescription from "../../UI/WysiwygDescription";
import FileUpload from "../../UI/FileUpload";

import { toastActions } from "../../store/toast";

import { RiSaveLine, RiEdit2Line } from "react-icons/ri";

import { AuthedUserContext } from "../../store/authed-user-context";
import { getLocalStorageData } from "../../util/auth";

const UpdateTicket = (props) => {
  const dispatch = useDispatch();

  const { token } = getLocalStorageData();

  const { permissions, _id: userId } = useContext(AuthedUserContext);
  const { canEditTickets } = permissions;

  const { ticket } = props;

  const [show, setShow] = useState(false);

  const [applicantsList, setApplicantsList] = useState([]);

  const [title, setTitle] = useState(ticket?.title);
  const [convertedContent, setConvertedContent] = useState(ticket?.description);
  const [company, setCompany] = useState(ticket?.company || "");
  const [applicant, setApplicant] = useState(ticket?.applicant || "");
  const [category, setCategory] = useState(ticket?.category || "");
  const [responsibles, setResponsibles] = useState(ticket?.responsibles || []);
  const [state, setState] = useState(ticket?.state);
  const [files, setFiles] = useState([]);

  const deadlineInputRef = useRef();

  const titleChangeHandler = (event) => {
    setTitle(event.target.value);
  };

  const descriptionChangeHandler = (content) => {
    setConvertedContent(content);
  };

  const companyChangeHandler = (selectedItem) => {
    setCompany(selectedItem);
  };

  const applicantChangeHandler = (selectedItem) => {
    setApplicant(selectedItem);
  };

  const categoryChangeHandler = (selectedItem) => {
    setCategory(selectedItem);
  };

  const responsiblesChangeHandler = (selectedItems) => {
    setResponsibles(selectedItems);
  };

  const stateChangeHandler = (event) => {
    setState(event.target.value);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { sendRequest: postTicketHandler } = useHttp();

  const [formData, setFormData] = useState({});
  const { sendRequest: fetchFormDataHandler } = useHttp();
  const fetchFormData = useCallback(() => {
    fetchFormDataHandler(
      {
        url: `${process.env.REACT_APP_ADDRESS}/api/tickets/form-data`,
        headers: {
          Authorization: "Bearer " + token,
        },
      },
      (data) => {
        setFormData(data);
      },
    );
  }, [fetchFormDataHandler, token]);

  useEffect(() => {
    fetchFormData();
  }, [fetchFormData]);

  // фильтруем сотрудников выбранной компании, пользователей с правами canPerformTickets и canAdministrateTickets из общего списка
  const fetchApplicants = useCallback(() => {
    const users = formData.applicants?.filter(
      (user) =>
        user.permissions.canPerformTickets ||
        user.permissions.canAdministrateTickets ||
        company?.alias === user.company.alias,
    );
    setApplicantsList(users);
  }, [formData, company]);

  useEffect(() => {
    fetchApplicants();
  }, [fetchApplicants]);

  const submitHandler = (event) => {
    event.preventDefault();

    const update = (data) => {
      if (data.ticket) {
        props.setTicket(data.ticket);
        handleClose();
        dispatch(
          toastActions.setState({
            variant: "success text-white",
            message: "Данные заявки изменены",
            show: true,
          }),
        );
      } else {
        dispatch(
          toastActions.setState({
            variant: "danger text-white",
            message: data.message,
            show: true,
          }),
        );
      }
    };

    const formData = new FormData();
    formData.append("_id", ticket._id);
    formData.append("title", title);
    formData.append("description", convertedContent);
    if (files.length > 0) {
      for (const singleFile of files) {
        formData.append("attachments", singleFile);
      }
    }
    formData.append("category", JSON.stringify(category));
    formData.append("company", JSON.stringify(company));
    formData.append("responsibles", JSON.stringify(responsibles));
    formData.append("applicant", JSON.stringify(applicant));
    formData.append("deadline", new Date(deadlineInputRef.current?.value));
    formData.append("state", state);

    postTicketHandler(
      {
        url: `${process.env.REACT_APP_ADDRESS}/api/tickets/update`,
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        isFormData: true,
        body: formData,
      },
      update,
    );
  };

  const ticketStates = [
    "Новая",
    "Не в работе",
    "В работе",
    "Выполнена",
    "Закрыта",
  ];

  return (
    <>
      {canEditTickets && ticket.state !== "Закрыта" && (
        <>
          {ticket.state !== "Новая" &&
            ticket.responsibles
              ?.map((user) => user._id.toString())
              .includes(userId) && <Dropdown.Divider />}
          <Dropdown.Item onClick={handleShow}>
            <RiEdit2Line /> Изменить
          </Dropdown.Item>
          <Modal show={show} onHide={handleClose} size="xl" centered>
            <Modal.Header closeButton>
              <Modal.Title>Изменить заявку</Modal.Title>
            </Modal.Header>
            <Form onSubmit={submitHandler}>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="title">Тема</Form.Label>
                  <Form.Control
                    required
                    autoFocus
                    id="title"
                    type="text"
                    value={title}
                    onChange={titleChangeHandler}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Описание</Form.Label>
                  <WysiwygDescription
                    id="description"
                    changeHandler={descriptionChangeHandler}
                    description={ticket?.description}
                  />
                </Form.Group>
                <FileUpload
                  setFiles={setFiles}
                  files={files}
                  showLabel={true}
                  showText={true}
                />
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="company">Компания</Form.Label>
                  <Select
                    id="company"
                    placeholder="Выберите компанию"
                    required
                    isClearable
                    isSearchable
                    options={formData.companies}
                    value={company}
                    getOptionLabel={(option) => `${option.alias}`}
                    getOptionValue={(option) => option._id}
                    onChange={companyChangeHandler}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="applicant">Инициатор</Form.Label>
                  <Select
                    id="applicant"
                    placeholder="Выберите пользователя"
                    required
                    isClearable
                    isSearchable
                    options={applicantsList}
                    value={applicant}
                    getOptionLabel={(option) =>
                      `${option.lastName} ${option.firstName}`
                    }
                    getOptionValue={(option) => option._id}
                    onChange={applicantChangeHandler}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="category">Категория</Form.Label>
                  <Select
                    id="category"
                    placeholder="Выберите категорию"
                    required
                    isClearable
                    isSearchable
                    value={category}
                    options={formData.categories}
                    getOptionLabel={(option) => `${option.title}`}
                    getOptionValue={(option) => option._id}
                    onChange={categoryChangeHandler}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="responsibles">Ответственные</Form.Label>
                  <Select
                    id="responsibles"
                    placeholder="Выберите пользователей"
                    required
                    closeMenuOnSelect={false}
                    isClearable
                    isSearchable
                    isMulti
                    value={responsibles}
                    options={formData.responsibles}
                    getOptionLabel={(option) =>
                      `${option.lastName} ${option.firstName}`
                    }
                    getOptionValue={(option) => option._id}
                    onChange={responsiblesChangeHandler}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Дедлайн</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    ref={deadlineInputRef}
                    required
                    defaultValue={
                      ticket.deadline
                        ? changeTimezone(new Date(ticket.deadline))
                        : ""
                    }
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="state">Статус</Form.Label>
                  <Form.Select
                    id="state"
                    placeholder="Выберите статус"
                    required
                    value={state}
                    onChange={stateChangeHandler}
                  >
                    {ticketStates.map((ticketState) => {
                      return <option key={ticketState}>{ticketState}</option>;
                    })}
                  </Form.Select>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Закрыть
                </Button>
                <Button variant="primary" type="submit">
                  <RiSaveLine /> Сохранить
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </>
      )}
    </>
  );
};

export default UpdateTicket;
