export function getInitialPrefsData() {
  const contacts = {
    address: localStorage.getItem("contactsAddress") || "",
    email: localStorage.getItem("contactsEmail") || "",
    tel: localStorage.getItem("contactsTel") || "",
  };

  const getScreen = {
    isActive:
      localStorage.getItem("getScreenIsActive") === "true" ? true : false,
  };

  const htmlTicketDesc =
    localStorage.getItem("htmlTicketDesc") === "true" ? true : false;

  const emailNotifications =
    localStorage.getItem("emailNotifications") === "true" ? true : false;

  return {
    contacts: contacts,
    getScreen: getScreen,
    hmtlTicketDesc: htmlTicketDesc,
    emailNotifications: emailNotifications,
  };
}
