import { redirect, json } from "react-router-dom";

import { getLocalStorageData } from "../../util/auth";

import AddTicket from "../../components/Ticket/AddModal";

const AddTicketPage = () => {
  return <AddTicket />;
};

export default AddTicketPage;

export async function loader() {
  document.title = "НОВАЯ ЗАЯВКА";

  const { token } = getLocalStorageData();

  const response = await fetch(
    `${process.env.REACT_APP_ADDRESS}/api/tickets/form-data`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  );

  if (!response.ok) {
    throw response;
  }

  return response;
}

export async function action() {
  return redirect("/tickets");
}
