import { useState } from 'react';

import Form from 'react-bootstrap/Form';

const PrefsContacts = (props) => {
    const [tel, setTel] = useState(props.prefs.contacts?.tel || '');
    const [email, setEmail] = useState(props.prefs.contacts?.email || '');
    const [address, setAddress] = useState(props.prefs.contacts?.address || '');

    const telChangeHandler = (event) => {
        setTel(event.target.value);
        props.prefs.contacts.tel = event.target.value;
    };

    const emailChangeHandler = (event) => {
        setEmail(event.target.value);
        props.prefs.contacts.email = event.target.value;
    };

    const addressChangeHandler = (event) => {
        setAddress(event.target.value);
        props.prefs.contacts.address = event.target.value;
    };
    return (
        <>
            <Form.Group className='mb-3'>
                <Form.Label>Телефон</Form.Label>
                <Form.Control
                    type='text'
                    value={tel}
                    onChange={telChangeHandler}
                />
            </Form.Group>
            <Form.Group className='mb-3'>
                <Form.Label>Email</Form.Label>
                <Form.Control
                    type='text'
                    value={email}
                    onChange={emailChangeHandler}
                />
            </Form.Group>
            <Form.Group className='mb-3'>
                <Form.Label>Адрес</Form.Label>
                <Form.Control
                    type='text'
                    value={address}
                    onChange={addressChangeHandler}
                />
            </Form.Group>
        </>
    );
};

export default PrefsContacts;
