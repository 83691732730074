import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";
import Image from "react-bootstrap/Image";
import Accordion from "react-bootstrap/Accordion";
import Alert from "react-bootstrap/Alert";

import ResetPassword from "../../components/User/ResetPassword";
import DeleteUser from "../../components/User/Delete";

import { RiEdit2Line, RiArrowGoBackFill } from "react-icons/ri";

import { formatDate, formatShortDate } from "../../util/format-date";

import Transitions from "../../animations/Transition";

import { AuthedUserContext } from "../../store/authed-user-context";

const ViewUser = ({ user, tickets }) => {
  const navigate = useNavigate();

  const { isAdmin, role } = useContext(AuthedUserContext);

  const {
    canSeeAllCompanyTickets,
    canUpdateChangelog,
    canAvoidWorks,
    canEditTickets,
    canPerformTickets,
    canAdministrateTickets,
    canDeleteTickets,
    canSeeAllTickets,
    canSeeWorksReport,
    canUseDashboard,
  } = user.permissions;

  return (
    <>
      <Transitions>
        <Row className="justify-content-md-end mb-3">
          <Col sm={2} className="mb-3">
            <Image src="/profilepic-placeholder.jpg" roundedCircle />
          </Col>
          <Col>
            <Link to={`/companies/${user.company._id}`}>
              <h5>{user.company.alias}</h5>
            </Link>
            <h3>
              {user.firstName} {user.lastName}
            </h3>
            <p className="lead">{user.position}</p>
            <p>
              <em>
                Последний вход:{" "}
                {user.lastLogin ? formatDate(user.lastLogin) : "никогда"}
              </em>
            </p>
          </Col>
          <Col sm="auto" className="mb-2">
            {user.isActive && <Badge bg="success">Включен</Badge>}
            {!user.isActive && <Badge bg="danger">Отключен</Badge>}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col sm="6">
            <h4>Контакты</h4>
            <Table>
              <tbody>
                <tr>
                  <th>Почта</th>
                  <td>
                    <a href={"mailto:" + user.email}>{user.email}</a>
                  </td>
                </tr>
                <tr>
                  <th>Телефон</th>
                  <td>
                    <a href={"tel:" + user.phone}>{user.phone}</a>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col sm="6">
            <h4>Права</h4>
            <Table>
              <tbody>
                {user.isServiceAccount && (
                  <>
                    <tr>
                      <th>Сервисный аккаунт</th>
                      <td>да</td>
                    </tr>
                  </>
                )}
                {!user.isServiceAccount && (
                  <>
                    <tr>
                      <th>Администратор</th>
                      <td>{user.isAdmin ? "да" : "нет"}</td>
                    </tr>
                    <tr>
                      <th>Роль</th>
                      <td>{user.role}</td>
                    </tr>

                    <tr>
                      <th>Разрешения</th>
                      <td>
                        {canSeeAllCompanyTickets && (
                          <Badge className="mx-1">
                            Может видеть все заявки своей компании
                          </Badge>
                        )}
                        {canUpdateChangelog && (
                          <Badge className="mx-1">
                            Может обновлять Changelog
                          </Badge>
                        )}
                        {canAvoidWorks && (
                          <Badge className="mx-1">
                            Может не отмечать работы
                          </Badge>
                        )}
                        {canPerformTickets && (
                          <Badge className="mx-1">Может выполнять заявки</Badge>
                        )}
                        {canAdministrateTickets && (
                          <Badge className="mx-1">
                            Может администрировать заявки
                          </Badge>
                        )}
                        {canEditTickets && (
                          <Badge className="mx-1">
                            Может редактировать заявки
                          </Badge>
                        )}
                        {canDeleteTickets && (
                          <Badge className="mx-1">Может удалять заявки</Badge>
                        )}
                        {canSeeAllTickets && (
                          <Badge className="mx-1">
                            Может видеть все заявки
                          </Badge>
                        )}
                        {canSeeWorksReport && (
                          <Badge className="mx-1">
                            Может видеть отчёт по работам
                          </Badge>
                        )}
                        {canUseDashboard && (
                          <Badge className="mx-1">
                            Может использовать дашборд
                          </Badge>
                        )}
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col sm="6">
            <h4>Уведомления</h4>
            <Table>
              <tbody>
                {/*  <tr>
                                <th>Почта</th>
                                <td>включены</td>
                            </tr> */}
                <tr>
                  <th>Telegram</th>
                  <td>
                    {user.notify?.byTelegram?.isActive
                      ? "включены"
                      : "отключены"}
                  </td>
                </tr>
                {/* <tr>
                                <th>СМС</th>
                                <td>отключены</td>
                            </tr> */}
              </tbody>
            </Table>
          </Col>
        </Row>
        {user.permissions?.canPerformTickets && (
          <Row className="mb-2">
            <Col className="mb-2">
              <h4>Категории</h4>
              {user.categories.map((category) => {
                return (
                  <Badge key={category.title} className="mx-1">
                    {category.title}
                  </Badge>
                );
              })}
            </Col>
          </Row>
        )}
        {user.getScreen?.api && (
          <Row className="mb-2">
            <Col sm="6">
              <h4>Интеграции</h4>
              <Table>
                <tbody>
                  <tr>
                    <th>Getscreen</th>
                    <td>{user.getScreen?.api ? "включен" : "отключен"}</td>
                  </tr>
                  {/* <tr>
                                 <th>СМС</th>
                                 <td>отключены</td>
                             </tr> */}
                </tbody>
              </Table>
            </Col>
          </Row>
        )}
        <Row className="mb-2">
          <Col className="mb-2">
            <h4>Заявки</h4>
            {tickets.length > 0 && (
              <Accordion className="mb-2">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>{`Раскрыть список (${tickets.length} записей)`}</Accordion.Header>
                  <Accordion.Body>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Номер</th>
                          <th>Тема</th>
                          <th>Создана</th>
                          <th>Статус</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tickets.map((ticket) => {
                          return (
                            <tr key={ticket._id}>
                              <td>
                                <Link
                                  to={"/tickets/" + ticket.num}
                                  target="_blank"
                                >
                                  {ticket.num}
                                </Link>
                              </td>
                              <td>{ticket.title}</td>
                              <td>{formatShortDate(ticket.createdAt)}</td>
                              <td>{ticket.state}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )}
            {tickets.length === 0 && (
              <Alert variant="light" className="text-centered mb-2">
                Заявки не найдены
              </Alert>
            )}
          </Col>
        </Row>
        <Row className="mt-2">
          <Col sm="auto">
            <Button onClick={() => navigate("/users")} className="mb-2 w-100">
              <RiArrowGoBackFill /> К списку
            </Button>
          </Col>
          {(isAdmin || role !== "ИТ-Специалист") && (
            <>
              <Col sm="auto">
                <Button as={Link} to={`update`} className="mb-2 w-100">
                  <RiEdit2Line /> Изменить
                </Button>
              </Col>
              {!user.isServiceAccount && (
                <Col sm="auto">
                  <ResetPassword user={user} />
                </Col>
              )}
              <Col sm="auto">
                <DeleteUser user={user} />
              </Col>
            </>
          )}
        </Row>
      </Transitions>
    </>
  );
};

export default ViewUser;
