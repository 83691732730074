import BaseSelect from "react-select";
import FixRequiredSelect from "../util/fix-required-select";
import { getLocalStorageData } from "../util/auth";

const Select = (props) => {
  const { darkMode } = getLocalStorageData();
  return (
    <FixRequiredSelect
      {...props}
      SelectComponent={BaseSelect}
      options={props.options}
      theme={(theme) =>
        darkMode
          ? {
              ...theme,
              color: "white",
              colors: {
                ...theme.colors,
                neutral0: "#999",
                neutral15: "#444",
                primary25: "#444",
                primary: "#444",
              },
            }
          : { ...theme }
      }
    />
  );
};

export default Select;
