import { useState, useRef } from "react";

import { useLoaderData } from "react-router-dom";

import Select from "../../UI/Select";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import NoModalForm from "../../UI/NoModalForm";
import PhoneInput from "../../UI/PhoneInput";

const CompanyForm = (props) => {
  const { company, responsibles: responsiblesList } = useLoaderData();

  const alias = useRef();
  const fullTitle = useRef();
  const domains = useRef();

  const [responsibles, setResponsibles] = useState(company?.responsibles || []);
  const responsiblesChangeHandler = (selectedItems) => {
    setResponsibles(selectedItems);
  };

  const [phoneNumber, setPhoneNumber] = useState(company?.phones[0]);

  return (
    <>
      <NoModalForm title={props.title}>
        <Row>
          <Col xl="6">
            <Form.Group className="mb-3">
              <Form.Label htmlFor="alias">
                Короткое наименование
                <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                required
                autoFocus
                id="alias"
                name="alias"
                type="text"
                defaultValue={company?.alias || ""}
                ref={alias}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xl="6">
            <Form.Group className="mb-3">
              <Form.Label htmlFor="fullTitle">
                Полное наименование
                <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                required
                id="fullTitle"
                name="fullTitle"
                type="text"
                defaultValue={company?.fullTitle || ""}
                ref={fullTitle}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xl="6">
            <Form.Group className="mb-3">
              <Form.Label htmlFor="domains">Почтовые домены</Form.Label>
              <Form.Control
                id="emailDomains"
                name="emailDomains"
                type="text"
                placeholder={"Введите через запятую без @"}
                defaultValue={company?.emailDomains}
                ref={domains}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xl="6">
            <Form.Group className="mb-3 w-100">
              <Form.Label htmlFor="phones">Телефон</Form.Label>
              <PhoneInput
                id="phones"
                name="phones"
                setValue={setPhoneNumber}
                value={phoneNumber}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xl="6">
            <Form.Group className="mb-3">
              <Form.Label htmlFor="responsibles">
                Ответственные
                <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Select
                required={responsiblesList ? true : false}
                id="responsibles"
                name="responsibles"
                placeholder="Выберите пользователей"
                closeMenuOnSelect={false}
                isClearable
                isSearchable
                isMulti
                value={responsibles}
                options={responsiblesList}
                getOptionLabel={(option) =>
                  `${option.lastName} ${option.firstName}`
                }
                getOptionValue={(option) => option._id}
                onChange={responsiblesChangeHandler}
              />
            </Form.Group>
          </Col>
        </Row>
      </NoModalForm>
    </>
  );
};

export default CompanyForm;
