import UserItem from './Item';

const UsersList = (props) => {
    return (
        <>
            {props.items.map((user) => (
                <UserItem
                    key={user._id.toString()}
                    user={user}
                    setUsersList={props.setUsersList}
                    companiesList={props.companiesList}
                    categoriesList={props.categoriesList}
                />
            ))}
        </>
    );
};

export default UsersList;
