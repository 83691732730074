import { useState, useEffect, useContext } from "react";
import { redirect, json, useLoaderData } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";

import { getLocalStorageData } from "../../util/auth";

import { RiAccountBoxLine } from "react-icons/ri";

import UsersList from "../../components/User/List";

import ListCardContent from "../../UI/ListCardContent";

import { SidebarContext } from "../../store/sidebar-context";

import UserFilter from "../../components/User/Filter";

import { Button, Offcanvas } from "react-bootstrap";

const Users = () => {
  const { users } = useLoaderData();

  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const [filteredUsers, setFilteredUsers] = useState(users);

  const title = () => {
    return (
      <>
        <RiAccountBoxLine /> Пользователи
      </>
    );
  };

  const { setLeftSidebarContent } = useContext(SidebarContext);

  useEffect(() => {
    setLeftSidebarContent(
      <BrowserView>
        <UserFilter
          users={users}
          filteredUsers={filteredUsers}
          setFilteredUsers={setFilteredUsers}
        />
      </BrowserView>,
    );
  }, []);

  return (
    <ListCardContent
      title={title}
      filteredList={filteredUsers}
      secondaryList={users}
      addRoute="/users/add"
    >
      <MobileView>
        <Button className="w-100 mb-3" onClick={() => setShowOffcanvas(true)}>
          Фильтр
        </Button>
        <Offcanvas
          show={showOffcanvas}
          onHide={() => {
            setShowOffcanvas(false);
          }}
        >
          <Offcanvas.Header closeButton>Фильтр</Offcanvas.Header>
          <Offcanvas.Body>
            <UserFilter
              users={users}
              filteredUsers={filteredUsers}
              setFilteredUsers={setFilteredUsers}
            />
          </Offcanvas.Body>
        </Offcanvas>
      </MobileView>
      <UsersList items={filteredUsers}></UsersList>
    </ListCardContent>
  );
};

export default Users;

export async function loader() {
  document.title = "F1 HD | ПОЛЬЗОВАТЕЛИ";
  const { token } = getLocalStorageData();

  if (!token) {
    return redirect("/auth");
  }

  const response = await fetch(`${process.env.REACT_APP_ADDRESS}/api/users`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  if (!response.ok) {
    if (response.status === 401 || response.status === 402) {
      return redirect("/auth");
    }
    throw json(
      { message: response.message },
      {
        status: response.status,
      },
    );
  } else {
    return response;
  }
}
