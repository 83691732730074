import { NavLink } from "react-router-dom";

import ItemCard from "../../UI/ItemCard";

import { RiBuilding2Line } from "react-icons/ri";

function UserItem({ user }) {
  const {
    _id = "",
    firstName = "",
    lastName = "",
    company = {},
    position = "",
    createdAt = "",
  } = user;

  return (
    <ItemCard createdAt={createdAt}>
      <h4 className="mb-3">
        <NavLink to={`${_id}`}>
          {lastName} {firstName}
        </NavLink>
      </h4>
      <p className={position ? "" : "mb-0"}>
        <RiBuilding2Line /> {company.alias}
      </p>
      <p className="mb-0">{position}</p>
    </ItemCard>
  );
}

export default UserItem;
