import { getLocalStorageData } from "../../util/auth";

export async function action(request, params) {
  const { token } = getLocalStorageData();

  const data = await request.formData();
  const intent = data.get("intent");

  if (intent === "update-сheck-list") {
    const ticketId = params.ticketId;

    const checklistItem = data.get("checklistItem");

    const response = await fetch(
      `${process.env.REACT_APP_ADDRESS}/api/tickets/${ticketId}/update-checklist`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(checklistItem),
      },
    );

    if ([409].includes(response.status)) {
      return response;
    }

    if (!response.ok) {
      throw response;
    }

    return response;
  }
}
