import React, { lazy, Suspense } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import App from "./App";

import store from "./store/index";
import BackToTop from "./UI/BackToTop";
import { getLocalStorageData } from "./util/auth";

import("./index.css");

const LightTheme = lazy(() => import("./layout/LightTheme"));
const DarkTheme = lazy(() => import("./layout/DarkTheme"));

const ThemeSelector = ({ children }) => {
  const { darkMode } = getLocalStorageData();

  const isLightTheme = () => {
    return darkMode !== true;
  };

  return (
    <>
      <Suspense fallback={null}>
        {isLightTheme() ? <LightTheme /> : <DarkTheme />}
      </Suspense>
      {children}
    </>
  );
};

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <ThemeSelector>
      <App />
      <BackToTop />
    </ThemeSelector>
  </Provider>,
);
