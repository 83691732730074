import { useState } from "react";

import Form from "react-bootstrap/Form";

import timezones from "../../store/timezones";

import Select from "../../UI/Select";

const PrefsGlobals = ({ prefs }) => {
  const [selectedTimezone, setSelectedTimezone] = useState(
    timezones.filter((zone) => zone.value === prefs.timezone),
  );

  const timezoneSelectHandler = (event) => {
    setSelectedTimezone(event);
    prefs.timezone = event.value;
  };
  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>Часовой пояс</Form.Label>
        <Select
          id="timezone"
          name="timezone"
          placeholder="Выберите часовой пояс"
          closeMenuOnSelect
          isClearable
          isSearchable
          value={selectedTimezone}
          options={timezones}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
          onChange={timezoneSelectHandler}
        />
      </Form.Group>
    </>
  );
};

export default PrefsGlobals;
