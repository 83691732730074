import { useState, useEffect } from "react";
import { redirect, json, useLoaderData } from "react-router-dom";

import { RiBuilding2Line } from "react-icons/ri";
import CompaniesList from "../../components/Company/List";
import { getLocalStorageData } from "../../util/auth";
import ListCardContent from "../../UI/ListCardContent";

const Companies = () => {
  const { companies } = useLoaderData();

  const [filteredCompanies, setFilteredCompanies] = useState(companies);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setFilteredCompanies(
      companies.filter((company) => {
        const string = company.alias + company.fullTitle;
        return string.toLowerCase().includes(searchTerm.toLowerCase());
      }),
    );
  }, [searchTerm, companies]);

  useEffect(() => {
    setFilteredCompanies(companies);
  }, [companies]);

  const title = () => {
    return (
      <>
        <RiBuilding2Line /> Компании
      </>
    );
  };

  return (
    <ListCardContent
      title={title}
      filteredList={filteredCompanies}
      setSearchTerm={setSearchTerm}
    >
      <CompaniesList items={filteredCompanies}></CompaniesList>
    </ListCardContent>
  );
};

export default Companies;

export async function loader() {
  document.title = "КОМПАНИИ";

  const { token } = getLocalStorageData();

  const companiesResponse = await fetch(
    `${process.env.REACT_APP_ADDRESS}/api/companies`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  );

  if (!companiesResponse.ok) {
    throw companiesResponse;
  }

  const responsiblesResponse = await fetch(
    `${process.env.REACT_APP_ADDRESS}/api/users/responsibles`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  );

  if (!responsiblesResponse.ok) {
    throw responsiblesResponse;
  }

  return {
    companies: await companiesResponse.json(),
    responsibles: await responsiblesResponse.json(),
  };
}
