import { useContext } from "react";

import Col from "react-bootstrap/Col";
import DropdownButton from "react-bootstrap/DropdownButton";
import ButtonGroup from "react-bootstrap/ButtonGroup";

import RequestHelp from "../Actions/RequestHelp";
import UpdateDeadline from "../Actions/UpdateDeadline";
import RejectTicket from "../Actions/Reject";
import UpdateTicketChecklist from "../Checklist/Update";
import UpdateTicket from "../Update";
import DeleteTicket from "../Delete";

import { AuthedUserContext } from "../../../store/authed-user-context";

const ActionDropdown = ({
  ticket,
  isOverdue,
  setIsOverdue,
  responsibles,
  setTicket,
}) => {
  const { permissions } = useContext(AuthedUserContext);
  const {
    canEditTickets,
    canDeleteTickets,
    canPerformTickets,
    canAdministrateTickets,
  } = permissions;

  return (
    <>
      {(canPerformTickets ||
        canAdministrateTickets ||
        canEditTickets ||
        canDeleteTickets) && (
        <Col sm="auto">
          <DropdownButton
            as={ButtonGroup}
            title={<strong>Дополнительно</strong>}
            className="w-100 pb-0 mb-2"
            align="end"
            size="lg"
          >
            <h5>
              <RequestHelp ticket={ticket} responsibles={responsibles} />
              <UpdateDeadline
                ticket={ticket}
                isOverdue={isOverdue}
                setIsOverdue={setIsOverdue}
              />
              <RejectTicket type="dropdown" ticket={ticket} />
              <UpdateTicketChecklist ticket={ticket} />
              <UpdateTicket ticket={ticket} setTicket={setTicket} />
              <DeleteTicket ticket={ticket} />
            </h5>
          </DropdownButton>
        </Col>
      )}
    </>
  );
};

export default ActionDropdown;
