import { useState, useEffect, useCallback, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";

import { MobileView } from "react-device-detect";

import { ticketFiltersActions } from "../../store/filter-tickets";

import { Row, Col, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import SearchBar from "../../UI/SearchBar";

import { AuthedUserContext } from "../../store/authed-user-context";

const isToday = (date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

const checkComments = (ticket) => {
  const now = new Date();
  const deadline = new Date(ticket.deadline);

  // Check if latestComment is absent
  if (!ticket.latestComment) {
    return "abcent";
  }

  const latestCommentDate = new Date(ticket.latestComment?.createdAt);

  // Check if created more than 2 days ago
  const oneDayAgo = new Date(now.getTime() - 1 * 24 * 60 * 60 * 1000);
  if (latestCommentDate < oneDayAgo) {
    return "more_than_1_day";
  }

  // Check if there were no comments after deadline
  if (latestCommentDate < deadline) {
    return "no_comments_after_deadline";
  }

  if (ticket.latestComment) {
    return "present";
  }

  return false;
};

const TicketFilter = ({
  tickets,
  setShowOffcanvas = () => {
    return null;
  },
}) => {
  const dispatch = useDispatch();

  const {
    isAdmin,
    permissions,
    role: userRole,
    _id: userId,
  } = useContext(AuthedUserContext);

  const { canSeeAllTickets = false, canAdministrateTickets = false } =
    permissions;

  const [filter, setFilter] = useState(
    useSelector((state) => state.ticketsFilter),
  );

  // Фильтруем заявки на мои, необработанные, просроченные и к выполнению на сегодня
  const iAmResponsible = (tickets) =>
    tickets.filter((ticket) =>
      ticket.responsibles.map((resp) => resp._id).includes(userId),
    );

  const allActiveTickets = tickets.filter((ticket) => !ticket.isClosed);

  const notProcessedTickets = tickets
    .filter((ticket) => !ticket.isClosed)
    .filter((ticket) => ticket.state === "Новая");

  const overdueTickets = tickets
    .filter((ticket) => !ticket.isClosed)
    .filter((ticket) => new Date(ticket.deadline) < new Date());

  const todayTickets = tickets
    .filter((ticket) => !ticket.isClosed)
    .filter((ticket) =>
      // дедлайн на сегодня или просрочен
      isToday(new Date(ticket.deadline)),
    );

  const iAmApplicantTickets = tickets.filter(
    (ticket) => ticket.applicant._id.toString() === userId && !ticket.isClosed,
  );

  const closedTickets = tickets.filter((ticket) => ticket.isClosed);

  const ticketToggleHandler = (nowActive) => {
    setFilter({
      ...filter,

      nowActive: nowActive,
    });
  };

  const iAmResponsibleToggleHandler = () => {
    setFilter({
      ...filter,
      onlyIAmResponsibleActiveTickets: false,
      iAmResponsible: !filter.iAmResponsible,
    });
    dispatch(ticketFiltersActions.setState(filter));
  };

  const respToggleHandler = (event) => {
    const value = event.target.value;
    setFilter({
      ...filter,
      responsibles: !filter.responsibles.includes(value)
        ? [...filter.responsibles, value]
        : filter.responsibles.filter((resp) => resp !== value),
    });
  };

  const commentsTogglehandler = (value) => {
    setFilter({
      ...filter,
      comments: value,
    });
  };

  const scheduledWorksToggleHandler = (value) => {
    setFilter({
      ...filter,
      scheduledWorks: value,
    });
  };

  const resetFilterHandler = () => {
    setFilter({
      nowActive: "all_active",
      iAmResponsible: false,
      companies: [],
      responsibles: [],
      comments: "any",
      scheduledWorks: "any",
    });
  };

  const searchItems = (query, items) => {
    if (!query) return items;

    // Split the query into individual terms (e.g., "Ольга Вознюк" becomes ["Ольга", "Вознюк"])
    const queryTerms = query.toLowerCase().split(" ").filter(Boolean);

    return items.filter((item) => {
      const fieldsToSearch = [
        String(item.num),
        item.company?.alias,
        item.title,
        `${item.applicant?.firstName} ${item.applicant?.lastName}`,
        item.applicant?.firstName,
        item.applicant?.lastName,
        item.applicant?.email,
        item.applicant?.phone,
        item.applicant?.position,
        item.applicant?.role,
        item.state,
        ...item.responsibles.flatMap((responsible) => [
          `${responsible?.firstName} ${responsible?.lastName}`,
          responsible?.firstName,
          responsible?.lastName,
          responsible?.email,
          responsible?.phone,
          responsible?.position,
          responsible?.role,
        ]),
      ];

      return queryTerms.every((term) =>
        fieldsToSearch.some(
          (field) => field && field.toLowerCase().includes(term),
        ),
      );
    });
  };

  // Поиск по тексту
  const [searchTerm, setSearchTerm] = useState("");

  const searchHandler = (e) => {
    const query = e.target.value;
    setSearchTerm(searchTerm);
    dispatch(
      ticketFiltersActions.setState({
        ...filter,
        tickets: searchItems(query, ticketFilter(tickets)),
      }),
    );
  };

  // функция последовательно отсеивает заявки согласно активным фильтрам
  const ticketFilter = useCallback(
    (tickets) => {
      return tickets
        .filter((ticket) => {
          switch (filter.nowActive) {
            case "all_active":
              return allActiveTickets.includes(ticket);
            case "today":
              return todayTickets.includes(ticket);
            case "not_processed":
              return notProcessedTickets.includes(ticket);
            case "overdue":
              return overdueTickets.includes(ticket);
            case "i_am_applicant":
              return iAmApplicantTickets.includes(ticket);
            case "recently_closed":
              return closedTickets.includes(ticket);
            default:
              return true;
          }
        })
        .filter((ticket) => {
          if (filter.companies?.length > 0) {
            return filter.companies.includes(ticket.company._id.toString());
          } else {
            return true;
          }
        })
        .filter((ticket) => {
          if (filter.responsibles?.length > 0) {
            const isEqual = (a, b) => a === b;
            return ticket.responsibles
              .map((resp) => resp._id.toString())
              .some((item2) =>
                filter.responsibles.some((item1) => isEqual(item1, item2)),
              );
          } else {
            return true;
          }
        })
        .filter((ticket) => {
          if (filter.iAmResponsible) {
            return ticket.responsibles.map((resp) => resp._id).includes(userId);
          } else {
            return true;
          }
        })
        .filter((ticket) => {
          if (searchTerm.length > 0) {
            return [
              ticket.num,
              ticket.company.alias,
              ticket.applicant.lastName,
              ticket.applicant.firstName,
              JSON.stringify(ticket.responsibles),
              ticket.title,
              ticket.state,
            ]
              .join(" ")
              .toLowerCase()
              .includes(searchTerm);
          } else {
            return true;
          }
        })
        .filter((ticket) => {
          switch (filter.comments) {
            case "present":
              return checkComments(ticket) === "present";
            case "abcent":
              return checkComments(ticket) === "abcent";
            case "more_than_1_day":
              return checkComments(ticket) === "more_than_1_day";
            case "no_comments_after_deadline":
              return checkComments(ticket) === "no_comments_after_deadline";
            default:
              return true;
          }
        })
        .filter((ticket) => {
          switch (filter.scheduledWorks) {
            case "present":
              return ticket.scheduledWorks?.length > 0;
            case "abcent":
              return ticket.scheduledWorks?.length === 0;
            default:
              return true;
          }
        });
    },
    [
      allActiveTickets,
      notProcessedTickets,
      overdueTickets,
      iAmApplicantTickets,
      closedTickets,
      todayTickets,
      searchTerm,
      userId,
      filter,
    ],
  );

  useEffect(() => {
    // изменяем список заявок в соответствии со значениями объекта filter
    dispatch(
      ticketFiltersActions.setState({
        ...filter,
        tickets: ticketFilter(tickets),
      }),
    );
  }, [tickets, filter]);

  // Получаем список компаний из всего списка заявок и исключаем дублирование
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    let array = [];
    tickets.forEach((ticket) => {
      if (
        !array
          .map((company) => company._id.toString())
          .includes(ticket.company?._id.toString())
      ) {
        array.push({
          _id: ticket.company?._id.toString(),
          alias: ticket.company?.alias,
        });
      }
    });
    setCompanies(array.sort((a, b) => a.alias.localeCompare(b.alias)));
  }, [tickets]);

  const companyToggleHandler = (event) => {
    const value = event.target.value;
    setFilter({
      ...filter,
      companies: !filter.companies?.includes(value)
        ? [...filter.companies, value]
        : filter.companies?.filter((company) => company !== value),
    });
  };

  // Получаем список ответственных из всего списка заявок и исключаем дублирование
  const [responsibles, setResponsibles] = useState([]);
  useEffect(() => {
    let array = [];
    tickets.forEach((ticket) => {
      ticket.responsibles.forEach((resp) => {
        if (
          !array
            .map((user) => user._id.toString())
            .includes(resp._id.toString())
        ) {
          array.push({
            _id: resp._id.toString(),
            name: `${resp.lastName} ${resp.firstName}`,
          });
        }
      });
    });
    setResponsibles(array.sort((a, b) => a.name.localeCompare(b.name)));
  }, [tickets]);

  const customFilters = [
    {
      value: "all_active",
      label: "Все активные",
      length: filter.iAmResponsible
        ? iAmResponsible(allActiveTickets).length
        : allActiveTickets.length,
      className: `py-2  ${filter.nowActive === "all_active" ? "text-info" : ""}`,
    },
    {
      value: "i_am_applicant",
      label: "Созданы мной",
      length: filter.iAmResponsible
        ? iAmResponsible(iAmApplicantTickets).length
        : iAmApplicantTickets.length,
      className: `py-2  ${filter.nowActive === "i_am_applicant" ? "text-info" : ""}`,
    },
    {
      value: "today",
      label: "Сегодня дедлайн",
      length: filter.iAmResponsible
        ? iAmResponsible(todayTickets).length
        : todayTickets.length,
      className: `py-2  ${filter.nowActive === "today" ? "text-info" : ""}`,
    },
    {
      value: "overdue",
      label: "Просроченные",
      length: filter.iAmResponsible
        ? iAmResponsible(overdueTickets).length
        : overdueTickets.length,
      className: `py-2 ${filter.nowActive === "overdue" ? "text-info" : overdueTickets.length > 0 ? "text-danger" : ""}`,
    },
    {
      value: "not_processed",
      label: "Не обработанные",
      length: filter.iAmResponsible
        ? iAmResponsible(notProcessedTickets).length
        : notProcessedTickets.length,
      className: notProcessedTickets.length > 0 ? "text-warning py-2" : "py-2",
    },
    {
      value: "recently_closed",
      label: "Закрыты за последние 14 дней",
      length: filter.iAmResponsible
        ? iAmResponsible(closedTickets).length
        : closedTickets.length,
      className: `py-2  ${filter.nowActive === "recently_closed" ? "text-info" : ""}`,
    },
  ];

  const commentsFilter = [
    {
      value: "any",
      label: "Любое значение",
      className: `py-2 ${filter.comments === "any" ? "text-info" : ""}`,
    },
    {
      value: "present",
      label: "Присутствуют",
      className: `py-2 ${filter.comments === "present" ? "text-info" : ""}`,
    },
    {
      value: "abcent",
      label: "Отсутствуют",
      className: `py-2 ${filter.comments === "abcent" ? "text-info" : ""}`,
    },
    {
      value: "more_than_1_day",
      label: "Нет более 24-х часов",
      className: `py-2 ${filter.comments === "more_than_1_day" ? "text-info" : ""}`,
    },
    {
      value: "no_comments_after_deadline",
      label: "Нет после нарушенного дедлайна",
      className: `py-2 ${filter.comments === "no_comments_after_deadline" ? "text-info" : ""}`,
    },
  ];

  const scheduledWorksFilter = [
    {
      value: "any",
      label: "Любое значение",
      className: `py-2 ${filter.scheduledWorks === "any" ? "text-info" : ""}`,
    },
    {
      value: "present",
      label: "Запланированы",
      className: `py-2 ${filter.scheduledWorks === "present" ? "text-info" : ""}`,
    },
    {
      value: "abcent",
      label: "Незапланированы",
      className: `py-2 ${filter.scheduledWorks === "abcent" ? "text-info" : ""}`,
    },
  ];

  const getListLengthBy = (list, itemName, item) => {
    let result = [];
    if (itemName === "company") {
      result = list.filter((ticket) => ticket.company?._id === item._id).length;
    } else if (itemName === "responsible") {
      result = list.filter((ticket) =>
        ticket.responsibles.map((resp) => resp._id).includes(item._id),
      ).length;
    }

    return result;
  };

  return (
    <div className="pb-2">
      <h3>Фильтр</h3>
      <Row className="py-2 border-top border-bottom">
        <Col fluid="true">
          <SearchBar onChange={searchHandler} size="md" />
        </Col>
      </Row>
      {(canAdministrateTickets || isAdmin || canSeeAllTickets) && (
        <Row className="py-2 border-bottom">
          <Col>
            <Form.Check
              type="switch"
              id="i-am-responsible"
              label="Назначены на меня"
              value={filter.iAmResponsible}
              checked={filter.iAmResponsible}
              onChange={iAmResponsibleToggleHandler}
            />
          </Col>
        </Row>
      )}
      <Row className="py-2 border-bottom">
        <Col>
          {customFilters.map((item) => (
            <Form.Check
              className={item.className}
              key={item.value}
              checked={item.value === filter.nowActive}
              label={`${item.label} (${item.length})`}
              value={item.value}
              id={item.value}
              type="radio"
              name="filter-group-state"
              onChange={() => {
                ticketToggleHandler(item.value);
              }}
              disabled={item.value !== "all_active" && item.length === 0}
            />
          ))}
        </Col>
      </Row>
      {userRole !== "Клиент" && (
        <>
          <Row className="py-2 border-bottom">
            <Col>
              <h5>
                <strong>Компании</strong>
              </h5>
              {companies.map((company) => {
                return (
                  <Form.Check
                    key={company._id}
                    label={`${company.alias} (${getListLengthBy(ticketFilter(tickets), "company", company)})`}
                    className={`
                      ${filter.companies?.includes(company._id) ? "text-info" : ""}
                      ${
                        getListLengthBy(
                          ticketFilter(tickets),
                          "company",
                          company,
                        ) === 0
                          ? "text-secondary"
                          : ""
                      } py-2`}
                    value={company._id}
                    id={`company-${company._id}`}
                    checked={filter.companies?.includes(company._id)}
                    type="checkbox"
                    name="filter-group-companies"
                    onChange={companyToggleHandler}
                  />
                );
              })}
            </Col>
          </Row>
          {(canAdministrateTickets || isAdmin) && (
            <Row className="py-2 border-bottom">
              <Col>
                <h5>
                  <strong>Ответственные</strong>
                </h5>
                {responsibles.map((resp) => {
                  return (
                    <Form.Check
                      key={resp._id}
                      className={`${filter.responsibles?.includes(resp._id) ? "text-info" : ""}
                        ${
                          getListLengthBy(
                            ticketFilter(tickets),
                            "responsible",
                            resp,
                          ) === 0
                            ? "text-secondary"
                            : ""
                        } py-2`}
                      label={`${resp.name} (${getListLengthBy(ticketFilter(tickets), "responsible", resp)})`}
                      value={resp._id}
                      id={`resp-${resp._id}`}
                      checked={filter.responsibles?.includes(resp._id)}
                      type="checkbox"
                      name="filter-group-responsibles"
                      onChange={respToggleHandler}
                    />
                  );
                })}
              </Col>
            </Row>
          )}
          <Row className="py-2 border-bottom">
            <Col>
              <h5>
                <strong>Комментарии</strong>
              </h5>
              {commentsFilter.map((item) => (
                <Form.Check
                  className={item.className}
                  key={item.value}
                  checked={item.value === filter.comments}
                  label={`${item.label}`}
                  value={item.value}
                  id={`comments-${item.value}`}
                  type="radio"
                  name="filter-group-comment"
                  onChange={() => {
                    commentsTogglehandler(item.value);
                  }}
                />
              ))}
            </Col>
          </Row>
          <Row className="py-2 border-bottom">
            <Col>
              <h5>
                <strong>Работы</strong>
              </h5>
              {scheduledWorksFilter.map((item) => (
                <Form.Check
                  className={item.className}
                  key={item.value}
                  checked={item.value === filter.scheduledWorks}
                  label={`${item.label}`}
                  value={item.value}
                  id={`scheduled-works-${item.value}`}
                  type="radio"
                  name="filter-group-scheduled-works"
                  onChange={() => {
                    scheduledWorksToggleHandler(item.value);
                  }}
                />
              ))}
            </Col>
          </Row>
          <Row className="py-2 border-bottom gap-3">
            <MobileView>
              <Col>
                <Button
                  className="w-100"
                  onClick={() => {
                    setShowOffcanvas(false);
                  }}
                >
                  Применить
                </Button>
              </Col>
            </MobileView>
            <Col>
              <Button
                className="w-100"
                variant="warning"
                onClick={resetFilterHandler}
              >
                Сбросить
              </Button>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default TicketFilter;
