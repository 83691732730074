import { useContext } from "react";

import WorkItem from "./Item";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import AddWork from "./Add";
import ScheduleWork from "./AddScheduled";
import ScheduledWorkItem from "./ScheduledItem";
import AlertMessage from "../../UI/AlertMessage";

import { AuthedUserContext } from "../../store/authed-user-context";

const WorksList = ({
  works,
  ticket,
  setWorks,
  responsibles,
  setCloseTicketIsActive,
  otherCompanyTickets,
}) => {
  const { role: userRole, _id: userId } = useContext(AuthedUserContext);

  const finishedWorks = works.filter((item) => item.finishedAt);
  const scheduledWorks = works.filter(
    (item) => !item.finishedAt && item.planningToStart,
  );

  return (
    <>
      <h3>Работы</h3>
      {userRole !== "Клиент" &&
        ticket.responsibles
          .map((user) => user._id.toString())
          .includes(userId) && (
          <Row className="mb-3">
            <AddWork
              ticket={ticket}
              responsibles={responsibles}
              setWorks={setWorks}
              setCloseTicketIsActive={setCloseTicketIsActive}
              otherCompanyTickets={otherCompanyTickets}
            />
            <ScheduleWork
              ticket={ticket}
              responsibles={responsibles}
              setWorks={setWorks}
              setCloseTicketIsActive={setCloseTicketIsActive}
              otherCompanyTickets={otherCompanyTickets}
            />
          </Row>
        )}
      <Row>
        <Col>
          {scheduledWorks.length > 0 && (
            <>
              {scheduledWorks.map((work) => (
                <ScheduledWorkItem
                  key={work._id.toString()}
                  work={work}
                  ticket={ticket}
                  responsibles={responsibles}
                  setWorks={setWorks}
                  works={works}
                  otherCompanyTickets={otherCompanyTickets}
                />
              ))}
            </>
          )}
          {finishedWorks.length > 0 && (
            <>
              {finishedWorks.map((work) => (
                <WorkItem
                  key={work._id.toString()}
                  work={work}
                  ticket={ticket}
                  responsibles={responsibles}
                  setWorks={setWorks}
                  works={works}
                  otherCompanyTickets={otherCompanyTickets}
                />
              ))}
            </>
          )}
          {scheduledWorks.length === 0 && finishedWorks.length === 0 && (
            <AlertMessage variant="light" message="Список пока пуст." />
          )}
        </Col>
      </Row>
    </>
  );
};

export default WorksList;
