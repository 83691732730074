import { useState } from 'react';

import Form from 'react-bootstrap/Form';

import ModalForm from '../../layout/ModalForm';
import { useLoaderData } from 'react-router-dom';

const TicketCategoryForm = (props) => {
    const data = useLoaderData();
    const [enteredTitle, setEnteredTitle] = useState(
        data ? data.title : ''
    );
    const [isActive, setIsActive] = useState(
        data ? data.isActive : true
    );
    const titleChangeHandler = (event) => {
        setEnteredTitle(event.target.value);
    };
    const isActiveChangeHandler = () => {
        setIsActive(!isActive);
    };

    return (
        <>
            <ModalForm title={props.title} navigateTo='/ticket-categories'>
                <Form.Group className='mb-3'>
                    <Form.Label htmlFor='title'>
                        Наименование
                        <span style={{ color: 'red' }}>*</span>
                    </Form.Label>
                    <Form.Control
                        autoFocus
                        id='title'
                        name='title'
                        type='text'
                        value={enteredTitle}
                        onChange={titleChangeHandler}
                    />
                </Form.Group>
                <Form.Check
                    checked={isActive}
                    type='switch'
                    id='isActive'
                    name='isActive'
                    label='Активна'
                    value={isActive}
                    onChange={isActiveChangeHandler}
                />
            </ModalForm>
        </>
    );
};

export default TicketCategoryForm;
