import { useState, useRef, useCallback, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import {
  useNavigate,
  useLoaderData,
  Form as RouterForm,
} from "react-router-dom";

import { BrowserView, MobileView } from "react-device-detect";

import Select from "../../UI/Select";

import useHttp from "../../hooks/use-http";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { toastActions } from "../../store/toast";

import { RiSaveLine, RiArrowGoBackFill } from "react-icons/ri";

import WysiwygDescription from "../../UI/WysiwygDescription";
import FileUpload from "../../UI/FileUpload";

import { AuthedUserContext } from "../../store/authed-user-context";
import { getLocalStorageData } from "../../util/auth";

const AddTicket = (props) => {
  // eslint-disable-next-line
  Date.prototype.addHours = function (h) {
    this.setHours(this.getHours() + h);
    return this;
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { token } = getLocalStorageData();

  const { role: userRole, permissions } = useContext(AuthedUserContext);
  const { canPerformTickets } = permissions;

  const [title, setTitle] = useState("");

  const ticketFormData = useLoaderData();

  const [convertedContent, setConvertedContent] = useState();

  const [company, setCompany] = useState({});
  const [applicant, setApplicant] = useState({});
  const [category, setCategory] = useState({});
  const [responsibles, setResponsibles] = useState([]);
  const [files, setFiles] = useState([]);
  const deadlineInputRef = useRef();

  const titleChangeHandler = (event) => {
    setTitle(event.target.value);
  };

  const descriptionChangeHandler = (content) => {
    setConvertedContent(content);
  };

  const mobileDescriptionChangeHandler = (event) => {
    setConvertedContent(event.target.value);
  };

  const companyChangeHandler = (selectedCompany) => {
    setCompany(selectedCompany);
  };

  const applicantChangeHandler = (selectedItem) => {
    setApplicant(selectedItem);
  };

  const categoryChangeHandler = (selectedItem) => {
    setCategory(selectedItem);
  };

  const responsiblesChangeHandler = (selectedUsers) => {
    setResponsibles(selectedUsers);
  };

  const { isLoading, sendRequest: postTicketHandler } = useHttp();

  const [applicantsList, setApplicantsList] = useState([]);

  // фильтруем сотрудников выбранной компании, пользователей с правами на выполнение заявок и менеджеров из общего списка
  const fetchApplicants = useCallback(() => {
    const users = ticketFormData.applicants?.filter(
      (user) =>
        user.permissions?.canPerformTickets ||
        company?._id === user.company._id,
    );
    setApplicantsList(users);
  }, [ticketFormData, company]);

  useEffect(() => {
    fetchApplicants();
  }, [fetchApplicants]);

  const submitHandler = async (event) => {
    event.preventDefault();

    const createTicket = (data) => {
      if (data.ticket) {
        dispatch(
          toastActions.setState({
            variant: "success text-white",
            message: "Заявка добавлена",
            show: true,
          }),
        );

        navigate(`/tickets/${data.ticket.num}`);
      } else {
        dispatch(
          toastActions.setState({
            variant: "danger text-white",
            message: data.message,
            show: true,
          }),
        );
      }
    };

    const formData = new FormData();

    function removeHtmlTags(str) {
      return str.replace(/<[^>]*>/g, "");
    }

    const titleFromDesc =
      convertedContent.length > 50
        ? `${convertedContent.substring(0, 50)}...`
        : convertedContent;

    formData.append(
      "title",
      userRole === "Клиент" ? removeHtmlTags(titleFromDesc) : title,
    );
    formData.append("description", convertedContent);

    if (files.length > 0) {
      for (const singleFile of files) {
        formData.append("attachments", singleFile);
      }
    }

    formData.append("category", JSON.stringify(category));

    formData.append(
      "company",
      userRole === "Клиент" ? "" : JSON.stringify(company),
    );
    formData.append("responsibles", JSON.stringify(responsibles));
    formData.append(
      "applicant",
      userRole === "Клиент" ? "" : JSON.stringify(applicant),
    );
    if (userRole !== "Клиент") {
      formData.append(
        "deadline",
        deadlineInputRef.current.value
          ? new Date(deadlineInputRef.current?.value)
          : "",
      );
    }

    formData.append(
      "state",
      responsibles.length === 0 ? "Новая" : "Не в работе",
    );
    formData.append("source", "Портал");

    await postTicketHandler(
      {
        url: `${process.env.REACT_APP_ADDRESS}/api/tickets/add`,
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        isFormData: true,
        body: formData,
      },
      createTicket,
    );
  };

  return (
    <>
      <h1>Новая заявка</h1>
      <hr></hr>
      <RouterForm onSubmit={submitHandler} method="post">
        {userRole !== "Клиент" && (
          <>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="title">
                Тема
                <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                required
                autoFocus
                id="title"
                type="text"
                value={title}
                onChange={titleChangeHandler}
              />
            </Form.Group>
          </>
        )}
        <Form.Label htmlFor="description">Описание</Form.Label>
        <BrowserView>
          <Form.Group className="mb-3">
            <WysiwygDescription
              id="description"
              changeHandler={descriptionChangeHandler}
              placeholder={
                userRole === "Клиент" ? "Опишите задачу или проблему." : ""
              }
            />
          </Form.Group>
        </BrowserView>
        <MobileView>
          <Form.Group className="mb-3">
            <Form.Control
              as="textarea"
              rows={5}
              id="description"
              onChange={mobileDescriptionChangeHandler}
              placeholder={
                userRole === "Клиент" ? "Опишите задачу или проблему." : ""
              }
            />
          </Form.Group>
        </MobileView>

        <FileUpload
          setFiles={setFiles}
          files={files}
          showLabel={true}
          showText={true}
        />
        {userRole !== "Клиент" && (
          <>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="company">
                Компания
                <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Select
                id="company"
                placeholder="Выберите компанию"
                required
                isClearable
                isSearchable
                options={ticketFormData.companies}
                getOptionLabel={(option) => `${option.alias}`}
                getOptionValue={(option) => option._id}
                onChange={companyChangeHandler}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="applicant">
                Инициатор
                <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Select
                id="applicant"
                placeholder="Выберите пользователя"
                required
                isClearable
                isSearchable
                options={applicantsList}
                getOptionLabel={(option) =>
                  `${option.lastName} ${option.firstName}`
                }
                getOptionValue={(option) => option._id}
                onChange={applicantChangeHandler}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="category">
                Категория
                <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Select
                id="category"
                placeholder="Выберите категорию"
                required
                isClearable
                isSearchable
                options={ticketFormData.categories}
                getOptionLabel={(option) => `${option.title}`}
                getOptionValue={(option) => option._id}
                onChange={categoryChangeHandler}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="responsibles">
                Ответственные
                {!canPerformTickets && <span style={{ color: "red" }}>*</span>}
              </Form.Label>
              <Select
                id="responsibles"
                placeholder="Выберите пользователей"
                required={!canPerformTickets}
                closeMenuOnSelect={false}
                isClearable
                isSearchable
                isMulti
                options={ticketFormData.responsibles}
                getOptionLabel={(option) =>
                  `${option.lastName} ${option.firstName}`
                }
                getOptionValue={(option) => option._id}
                onChange={responsiblesChangeHandler}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Дедлайн</Form.Label>
              <Form.Control type="datetime-local" ref={deadlineInputRef} />
            </Form.Group>
          </>
        )}
        <hr></hr>
        <Row>
          <Col xs="auto">
            <Button
              onClick={() => navigate(-1, { state: {} })}
              className="mb-2"
              variant="secondary"
              disabled={isLoading}
            >
              <RiArrowGoBackFill /> Назад
            </Button>
          </Col>
          <Col xs="auto">
            <Button variant="primary" type="submit" disabled={isLoading}>
              <RiSaveLine /> Сохранить
            </Button>
          </Col>
        </Row>
      </RouterForm>
    </>
  );
};

export default AddTicket;
