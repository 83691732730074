import { useState, useEffect, useContext } from "react";
import { useLoaderData } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { ticketFiltersActions } from "../../store/filter-tickets";

import { BrowserView, MobileView } from "react-device-detect";

import { getLocalStorageData } from "../../util/auth";
import { SidebarContext } from "../../store/sidebar-context";
import { AuthedUserContext } from "../../store/authed-user-context";

import { TbCheckbox } from "react-icons/tb";

import TicketsList from "../../components/Ticket/List";

import ListCardContent from "../../UI/ListCardContent";

import TicketFilter from "../../components/Ticket/Filter";

import { Offcanvas, Row, Col, Form } from "react-bootstrap";

const Tickets = () => {
  const { setLeftSidebarContent } = useContext(SidebarContext);
  const { _id: userId } = useContext(AuthedUserContext);
  const dispatch = useDispatch();

  const filter = useSelector((state) => state.ticketsFilter);

  const { tickets } = useLoaderData();
  const activeTickets = tickets.filter((ticket) => !ticket.isClosed);

  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const iAmResponsible = (tickets) =>
    tickets.filter((ticket) =>
      ticket.responsibles.map((resp) => resp._id).includes(userId),
    );

  const iAmResponsibleToggleHandler = () => {
    dispatch(
      ticketFiltersActions.setState({
        nowActive: "all_active",
        comments: "any",
        scheduledWorks: "any",
        iAmResponsible: false,
        onlyIAmResponsibleActiveTickets:
          !filter.onlyIAmResponsibleActiveTickets,
        tickets: filter.onlyIAmResponsibleActiveTickets
          ? activeTickets
          : iAmResponsible(activeTickets),
      }),
    );
  };

  const showOffcanvasChangeHandler = () => {
    setShowOffcanvas(!showOffcanvas);
  };

  const title = () => {
    return (
      <>
        <TbCheckbox /> Заявки
      </>
    );
  };

  useEffect(() => {
    setLeftSidebarContent(
      <BrowserView>
        <TicketFilter tickets={tickets} />
      </BrowserView>,
    );
  }, [setLeftSidebarContent, tickets]);

  return (
    <>
      <ListCardContent
        title={title}
        filteredList={filter.isInitial ? activeTickets : filter.tickets}
        secondaryList={tickets}
        hideLengthRow
        addRoute="/tickets/add"
      >
        <MobileView>
          <Row className="py-2 mt-4">
            <Col>
              <Form.Check
                type="switch"
                className="form-control-lg"
                id="i-am-responsible-mobile"
                label="Только мои активные заявки"
                value={filter.onlyIAmResponsibleActiveTickets}
                checked={filter.onlyIAmResponsibleActiveTickets}
                onChange={iAmResponsibleToggleHandler}
              />
            </Col>
          </Row>
          <Offcanvas
            show={showOffcanvas}
            onHide={() => {
              setShowOffcanvas(false);
            }}
          >
            <Offcanvas.Header closeButton>Фильтр</Offcanvas.Header>
            <Offcanvas.Body>
              <TicketFilter
                tickets={tickets}
                setShowOffcanvas={setShowOffcanvas}
              />
            </Offcanvas.Body>
          </Offcanvas>
        </MobileView>
        <Row className="justify-content-between text-secondary my-3  fs-5">
          <Col>{`Найдено: ${filter.isInitial ? activeTickets.length : filter.tickets.length}`}</Col>
          <Col
            className="text-end text-success"
            onClick={showOffcanvasChangeHandler}
          >
            <u>Фильтр</u>
          </Col>
        </Row>
        <TicketsList
          items={filter.isInitial ? activeTickets : filter.tickets}
        ></TicketsList>
      </ListCardContent>
    </>
  );
};

export default Tickets;

export async function loader() {
  document.title = "ЗАЯВКИ";

  const { token } = getLocalStorageData();

  const response = await fetch(
    `${process.env.REACT_APP_ADDRESS}/api/tickets/all-opened`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  );

  if (!response.ok) {
    throw response;
  }

  return response;
}
