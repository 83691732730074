import { useRef, useState } from "react";
import { useFetcher } from "react-router-dom";

import useHttp from "../../hooks/use-http";

import FileUpload from "../../UI/FileUpload";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { RiAddBoxLine } from "react-icons/ri";

import { getLocalStorageData } from "../../util/auth";

const AddComment = (props) => {
  const fetcher = useFetcher();
  const { token } = getLocalStorageData();

  const content = useRef();

  const [files, setFiles] = useState([]);

  const { sendRequest: postCommentHandler } = useHttp();

  const submitHandler = (event) => {
    event.preventDefault();

    const formData = new FormData();

    formData.append("intent", "addComment");
    formData.append("content", content.current.value);
    formData.append("ticket", props.ticket.num);
    for (const singleFile of files) {
      formData.append("attachments", singleFile);
    }

    // костыль, не могу разобраться как цеплять файлы через useFetcher

    /* fetcher.submit(formData, {
            method: 'POST',
            action: `/tickets/${props.ticket.num}`,
        });

        content.current.value = '';
        setFiles([]); */

    const createComment = (data) => {
      if (data.comment) {
        const createdComment = {
          _id: data.comment._id,
          content: data.comment.content,
          ticket: props.ticket.num,
          attachments: data.comment.attachments,
          createdBy: data.comment.createdBy,
          createdAt: data.comment.createdAt,
        };
        props.setComments((prevCommentsList) => [
          ...prevCommentsList,
          createdComment,
        ]);
        content.current.value = "";
        setFiles([]);
      } else {
      }
    };

    postCommentHandler(
      {
        url: `${process.env.REACT_APP_ADDRESS}/api/comments/add/`,
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        isFormData: true,
        body: formData,
      },
      (data) => {
        createComment(data);
      },
    );
  };
  return (
    <>
      <Form onSubmit={submitHandler}>
        <Row>
          <Col className="mb-2">
            <Form.Group>
              <FormControl
                as="textarea"
                id="content"
                name="content"
                required
                rows={2}
                ref={content}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3 justify-content-between">
          <Col sm="7">
            <FileUpload
              id="attachments"
              name="attachments"
              setFiles={setFiles}
              files={files}
            />
          </Col>
          <Col sm="auto">
            <Form.Group>
              <Button
                type="submit"
                name="intent"
                value="addComment"
                className="w-100"
                disabled={fetcher.state !== "idle"}
              >
                <RiAddBoxLine /> Добавить
              </Button>
              <FormControl
                hidden
                id="ticket"
                name="ticket"
                defaultValue={props.ticket.num}
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default AddComment;
