import { json, redirect } from "react-router-dom";

import Form from "../../components/TicketCategory/Form";
import { getLocalStorageData } from "../../util/auth";

const UpdateTicketCategoryPage = () => {
  return <Form title="Изменить категорию заявок" />;
};

export default UpdateTicketCategoryPage;

export async function loader({ params }) {
  document.title = "ИЗМЕНИТЬ КАТЕГОРИЮ ЗАЯВОК";

  const { token } = getLocalStorageData();

  const response = await fetch(
    `${process.env.REACT_APP_ADDRESS}/api/ticket-categories/${params.id}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  );

  if (!response.ok) {
    throw response;
  }

  return response;
}

export async function action({ request, params }) {
  const { token } = getLocalStorageData();

  const data = await request.formData();

  const categoryData = {
    title: data.get("title"),
    isActive: data.get("isActive") === "true",
  };

  const response = await fetch(
    `${process.env.REACT_APP_ADDRESS}/api/ticket-categories/update/${params.id}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(categoryData),
    },
  );

  if ([409].includes(response.status)) {
    return response;
  }

  if (!response.ok) {
    throw response;
  }

  return redirect("/ticket-categories");
}
