import { useActionData } from "react-router-dom";

import AlertToast from "../../UI/AlertToast";

import ToastContainer from "react-bootstrap/ToastContainer";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Signup from "./Signup";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";

const AuthForm = () => {
  const data = useActionData();

  return (
    <Card className="shadow mb-2">
      <Card.Body>
        <ToastContainer className="p-3" position="bottom-end">
          <AlertToast />
        </ToastContainer>
        <Row className="pb-5 pt-5">
          <h2 className="text-center mb-1">F1LAB HELPDESK</h2>
          <h5 className="text-center mb-4">Техподдержка</h5>
          <Login data={data} />
          <Signup data={data} />
          <ForgotPassword data={data} />
        </Row>
      </Card.Body>
    </Card>
  );
};

export default AuthForm;
