import { createSlice } from "@reduxjs/toolkit";

const initialTicketFilterState = {
  isInitial: true,
  nowActive: "all_active",
  iAmResponsible: false,
  onlyIAmResponsibleActiveTickets: false,
  companies: [],
  responsibles: [],
  comments: "any",
  scheduledWorks: "any",
  tickets: [],
};

const ticketFiltersSlice = createSlice({
  name: "ticketsFilter",
  initialState: initialTicketFilterState,
  reducers: {
    setState(state, action) {
      state.isInitial = false;
      state.nowActive = action.payload.nowActive;
      state.iAmResponsible = action.payload.iAmResponsible;
      state.onlyIAmResponsibleActiveTickets =
        action.payload.onlyIAmResponsibleActiveTickets;
      state.companies = action.payload.companies;
      state.responsibles = action.payload.responsibles;
      state.comments = action.payload.comments;
      state.scheduledWorks = action.payload.scheduledWorks;
      state.tickets = action.payload.tickets;
    },
  },
});

export const ticketFiltersActions = ticketFiltersSlice.actions;

export default ticketFiltersSlice;
