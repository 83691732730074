import { RouterProvider, createBrowserRouter } from "react-router-dom";

import "sortable-tablesort/sortable.min.js";

// Root
import RootLayout from "./layout/Root";

// Dashboard
import Dashboard, { loader as dashboardLoader } from "./pages/Dashboard";

// Tickets
import Tickets, { loader as ticketsLoader } from "./pages/Ticket/List";

import ViewTicket, {
  loader as viewTicketLoader,
  action as viewTicketAction,
} from "./pages/Ticket/View";

import AddTicketPage, {
  loader as addTicketLoader,
  action as addTicketAction,
} from "./pages/Ticket/Add";

import { action as updateTicketAction } from "./pages/Ticket/Update";

import TicketsArchive, {
  loader as ticketsArchiveLoader,
} from "./pages/Ticket/Archive";

// Companies
import Companies, { loader as companiesLoader } from "./pages/Company/List";

import AddCompanyPage, {
  loader as addCompanyLoader,
  action as addCompanyAction,
} from "./pages/Company/Add";

import UpdateCompanyPage, {
  loader as updateCompanyLoader,
  action as updateCompanyrAction,
} from "./pages/Company/Update";

import ViewCompanyPage, {
  loader as viewCompanyLoader,
  action as viewCompanyAction,
} from "./pages/Company/View";

// Ticket categories
import TicketCatogries, {
  loader as ticketCategoriesLoader,
  action as deleteTicketCategoryAction,
} from "./pages/TicketCategory/List";

import AddTicketCategoryPage, {
  loader as addTicketCategoryLoader,
  action as addTicketCategoryAction,
} from "./pages/TicketCategory/Add";

import UpdateTicketCategoryPage, {
  action as updateTicketCategoryAction,
  loader as updateTicketCategoryLoader,
} from "./pages/TicketCategory/Update";

// Routine tasks
import RoutineTask, {
  loader as routineTaskLoader,
  action as deleteRoutineTaskAction,
} from "./pages/RoutineTask/List";

import AddRoutineTaskPage, {
  loader as addRoutineTaskLoader,
  action as addRoutineTaskAction,
} from "./pages/RoutineTask/Add";

import UpdateRoutineTaskPage, {
  action as updateRoutineTaskAction,
  loader as updateRoutineTaskLoader,
} from "./pages/RoutineTask/Update";

// Mikrotik devices
import MikrotikDevices, {
  loader as mikrotikDevicesLoader,
} from "./pages/Mikrotik/List";

import AddMikrotikDevicePage, {
  action as AddMikrotikDeviceAction,
} from "./pages/Mikrotik/Add";

import UpdateMikrotikDevicePage, {
  action as UpdateMikrotikDeviceInfoAction,
  loader as UpdateMikrotikDeviceInfoLoader,
} from "./pages/Mikrotik/Update";

// Users
import Users, { loader as usersLoader } from "./pages/User/List";

import AddUserPage, {
  loader as addUserLoader,
  action as addUserAction,
} from "./pages/User/Add";

import UpdateUserPage, {
  loader as updateUserLoader,
  action as updateUserAction,
} from "./pages/User/Update";

import MyAccount, {
  loader as myAccountLoader,
  action as myAccountAction,
} from "./pages/User/MyAccount";

import ViewUserPage, {
  loader as viewUserLoader,
  action as viewUserAction,
} from "./pages/User/View";

// Preferences
import Preferences, {
  loader as prefsLoader,
  action as prefsAction,
} from "./pages/Preferences";

// Reports
import WorkReport, {
  loader as workReportLoader,
} from "./pages/Report/WorkReport.js";

import CompaniesNetworksReport, {
  loader as companiesNetworksLoader,
} from "./pages/Report/CompaniesNetworksReport";

// Changelog
import Changelog, { loader as changelogLoader } from "./pages/Changelog/List";

import AddChangelog, {
  action as addChangelogAction,
} from "./pages/Changelog/Add";

// Auth
import Authentication, {
  loader as authLoader,
  action as authAction,
} from "./pages/Authentication";
import { authDataLoader, checkAuthLoader } from "./util/auth";
import { action as logoutAction } from "./components/Auth/Logout";

// Errors
import Error from "./pages/Error";

function App() {
  const router = createBrowserRouter([
    // Auth
    {
      path: "auth",
      element: <Authentication />,
      loader: authLoader,
      action: authAction,
    },
    {
      path: "/",
      element: <RootLayout />,
      errorElement: <Error />,
      id: "root",
      loader: authDataLoader,
      children: [
        // Index
        {
          index: true,
          element: <Dashboard />,
          loader: dashboardLoader,
        },

        {
          path: "logout",
          loader: checkAuthLoader,
          action: logoutAction,
        },
        // Dashboard
        {
          path: "dashboard",
          element: <Dashboard />,
          loader: dashboardLoader,
        },
        // Tickets
        {
          path: "tickets",
          element: <Tickets />,
          loader: ticketsLoader,
          children: [],
        },
        {
          path: "tickets/add",
          loader: addTicketLoader,
          action: addTicketAction,
          element: <AddTicketPage />,
        },
        {
          path: "tickets/update/:ticketId",
          action: updateTicketAction,
        },
        {
          path: "tickets/:ticketNum/*",
          loader: viewTicketLoader,
          action: viewTicketAction,
          element: <ViewTicket />,
        },
        {
          path: "closed-tickets",
          element: <TicketsArchive />,
          loader: ticketsArchiveLoader,
        },
        // Companies
        {
          path: "companies",
          element: <Companies />,
          loader: companiesLoader,
        },
        {
          path: "companies/add",
          loader: addCompanyLoader,
          action: addCompanyAction,
          element: <AddCompanyPage />,
        },
        {
          path: "companies/:id/update",
          loader: updateCompanyLoader,
          action: updateCompanyrAction,
          element: <UpdateCompanyPage />,
        },
        {
          path: "companies/:id",
          loader: viewCompanyLoader,
          action: viewCompanyAction,
          element: <ViewCompanyPage />,
        },
        // Users
        {
          path: "users",
          element: <Users />,
          loader: usersLoader,
        },
        {
          path: "users/add",
          loader: addUserLoader,
          action: addUserAction,
          element: <AddUserPage />,
        },
        {
          path: "users/:id/update",
          loader: updateUserLoader,
          action: updateUserAction,
          element: <UpdateUserPage />,
        },
        {
          path: "users/:id",
          loader: viewUserLoader,
          action: viewUserAction,
          element: <ViewUserPage />,
        },
        {
          path: "my-account",
          element: <MyAccount />,
          loader: myAccountLoader,
          action: myAccountAction,
        },
        // Ticket Categories
        {
          path: "ticket-categories",
          element: <TicketCatogries />,
          loader: ticketCategoriesLoader,
          action: deleteTicketCategoryAction,
          children: [
            {
              path: "add",
              loader: addTicketCategoryLoader,
              action: addTicketCategoryAction,
              element: <AddTicketCategoryPage />,
            },
            {
              path: "update/:id",
              loader: updateTicketCategoryLoader,
              action: updateTicketCategoryAction,
              element: <UpdateTicketCategoryPage />,
            },
            {
              path: "delete/:id",
            },
          ],
        },
        // Routine tasks
        {
          path: "routine-tasks",
          element: <RoutineTask />,
          loader: routineTaskLoader,
          action: deleteRoutineTaskAction,
        },
        {
          path: "routine-tasks/add",
          loader: addRoutineTaskLoader,
          action: addRoutineTaskAction,
          element: <AddRoutineTaskPage />,
        },
        {
          path: "routine-tasks/update/:id",
          loader: updateRoutineTaskLoader,
          action: updateRoutineTaskAction,
          element: <UpdateRoutineTaskPage />,
        },
        {
          path: "routine-tasks/delete/:id",
        },
        // Devices
        {
          path: "devices/mikrotik",
          element: <MikrotikDevices />,
          loader: mikrotikDevicesLoader,
          children: [
            {
              path: "add",
              action: AddMikrotikDeviceAction,
              element: <AddMikrotikDevicePage />,
            },
            {
              path: "update-info/:id",
              loader: UpdateMikrotikDeviceInfoLoader,
              action: UpdateMikrotikDeviceInfoAction,
              element: <UpdateMikrotikDevicePage />,
            },
          ],
        },
        // Reports
        {
          path: "report/work",
          element: <WorkReport />,
          loader: workReportLoader,
        },
        {
          path: "report/networks",
          element: <CompaniesNetworksReport />,
          loader: companiesNetworksLoader,
        },
        // Preferences
        {
          path: "preferences",
          element: <Preferences />,
          loader: prefsLoader,
          action: prefsAction,
        },
        // Changelog
        {
          path: "changelog",
          element: <Changelog />,
          loader: changelogLoader,
          children: [
            {
              path: "add",
              element: <AddChangelog />,
              action: addChangelogAction,
            },
          ],
        },
      ],
    },
  ]);
  return <RouterProvider router={router} />;
}

export default App;
