import { createSlice } from '@reduxjs/toolkit';

const initialPrefsState = {
    contacts: {
        tel: '',
        email: '',
        address: '',
    },
    htmlTicketDesc: false,
    getScreen: {
        isActive: false,
    },
};

const prefsSlice = createSlice({
    name: 'prefs',
    initialState: initialPrefsState,
    reducers: {
        setState(state, action) {
            state.contacts = action.payload.contacts;
            state.htmlTicketDesc = action.payload.htmlTicketDesc;
            state.getScreen = action.payload.getScreen;
        },
    },
});

export const prefsActions = prefsSlice.actions;

export default prefsSlice;
